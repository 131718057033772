import { http } from "../../helper/http";
import { PaginatedSchedule } from "./SchedulingReducer";

interface SetScheduleAction {
    type: "SET_SCHEDULE";
    payload: PaginatedSchedule;
}

interface updateSchedule {
    type: "UPDATE_SCHEDULE";
    payload: {id:number, schedule: any };
}

interface addSchedule {
    type: "ADD_SCHEDULE";
    payload: {schedule: any, totalItems: number};
}

export type ScheduleAction = 
| SetScheduleAction
| updateSchedule
| addSchedule




const baseUrl = process.env.REACT_APP_BASE_URL;


export async function setSchedule(dispatch: React.Dispatch<ScheduleAction>, payload: number,  searchSchedule: string | null = null) {
    try {
        const page = payload?? 1;
        let url = baseUrl + `/scheduling?page=${page}`;

        if(searchSchedule) {
            url += `&search=${searchSchedule}`
        }
        const response = (await http(url)) as any
        dispatch({
            type: "SET_SCHEDULE",
            payload: response,
        });
    } catch (error) {
        console.error(error);
        
    }
}


export  function updateSchedules(dispatch: React.Dispatch<ScheduleAction>, payload: {id: number, schedule:any}) {
        dispatch({
            type: "UPDATE_SCHEDULE",
            payload,
        });
 
}


export  function addSchedules(dispatch: React.Dispatch<ScheduleAction>, payload: {totalItems: number,schedule:any}) {
    dispatch({
        type: "ADD_SCHEDULE",
        payload,
    });

}
