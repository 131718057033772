import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { http } from "../../../helper/http";
import EnhancedTableHeader from "./EnhancedTableHeader";
import Loader from "../../../components/Loader/Loader";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { generateCSV1 } from "../../../common-function/CommonFunction";
import { NavLink } from "react-router-dom";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import indianStateCityData from "../../../helper/IndianStateCityData";

const baseUrl = process.env.REACT_APP_BASE_URL;
function ACUserDashboard() {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allDevices, setAllDevices] = useState<any>([]);
  const [totalDevices, setTotalDevices] = useState<any>("");
  const [activeDevices, setActiveDevices] = useState<number>(0);
  const [inactiveDevices, setInactiveDevices] = useState<number>(0);
  // commented for future implementation
  // const [totalScheduleOn, setTotalScheduleOn] = useState<number>(0);
  // const [totalScheduleOff, setTotalScheduleOff] = useState<number>(0);
  const [totalItems, setTotalItems] = useState(0);
  const [filterActive, setFilterActive] = useState(false);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [city, setCity] = useState<any>([]);
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [allAreas, setAllAreas] = useState<any>([]);
  const [area, setArea] = useState<any>("");

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const overviewResp = (await http(
          `${baseUrl}/overview-dashboard?key=ac&page=${page}&rowsPerPage=${rowsPerPage}&searchTerm=${searchTerm}&state=${
            selectedState === null ? "" : selectedState
          }&city=${selectedCity === null ? "" : selectedCity}&area=${area}`
        )) as any;
        setAllAreas(overviewResp.allAreas || []);
        setAllDevices(overviewResp.responseAc || []);
        if (overviewResp.filteredDevicesCount) {
          setTotalItems(overviewResp.filteredDevicesCount);
        } else {
          setTotalItems(overviewResp.totalDevices);
        }
        setTotalDevices(overviewResp.totalDevices);
        setActiveDevices(overviewResp.totalActiveDevices);
        setInactiveDevices(
          overviewResp.totalDevices - overviewResp.totalActiveDevices
        );
        // commented for Future implementation for Schedulecount
        // setTotalScheduleOn(overviewResp.totalScheduleOn);
        // setTotalScheduleOff(
        //   overviewResp.totalScheduled - overviewResp.totalScheduleOn
        // );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchAllData();
  }, [searchTerm, page, rowsPerPage, selectedState, selectedCity, area]);

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterClick = () => {
    setFilterActive((prev) => !prev);
    setSelectedState(null);
    setCity([]);
    setAllAreas([]);
    setSelectedCity(null);
    setArea("");
  };

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    setSelectedState(event.target.value);
    setSelectedCity("");
    const selectedState1 = indianStateCityData.states.find(
      (state) => state.name === event.target.value
    );
    setCity(selectedState1?.cities);
  };

  const handleCityChange = (event: SelectChangeEvent<string>) => {
    setSelectedCity(event.target.value);
  };

  const handleAreaChange = (event: any) => {
    setArea(event.target.value);
  };

  const handleClickExport = () => {
    let excelData = allDevices?.map((device: any) => {
      let acStatus = "OFF";
      let temperature = "N/A";
      let humidity = "N/A";
      let set_temp = "N/A";
      let tempurary_sch_time_on = device.scheduling
        ? device.scheduling.schedulerOn
        : "";
      let tempurary_sch_time_off = device.scheduling
        ? device.scheduling.schedulerOff
        : "";
      let sch_time =
        tempurary_sch_time_on && tempurary_sch_time_off
          ? `${tempurary_sch_time_on} - ${tempurary_sch_time_off}`
          : "N/A";
      let tempurary_sch_status = device.scheduling
        ? device.scheduling.triggerStatus
        : false;
      let sch_status = tempurary_sch_status ? "ON" : "OFF";
      let deviceStatus = device.status ? "Active" : "Inactive";

      device.deviceDataLogs.forEach((log: any) => {
        switch (log.tagId) {
          case 189:
            acStatus = log.value;
            break;
          case 191:
            temperature = log.value;
            break;
          case 188:
            humidity = log.value;
            break;
          case 190:
            set_temp = log.value;
            break;
          default:
            break;
        }
      });

      return {
        "Device Name": device.deviceName,
        "Device Alias": device.deviceAlias,
        "AC Status": acStatus,
        Temperature: temperature,
        Humidity: humidity,
        Set_Temp: set_temp,
        "SCH. Time": sch_time,
        "SCH. Status": sch_status,
        "Device Status": deviceStatus,
      };
    });
    generateCSV1(excelData);
  };

  return (
    <>
      <div className="flex gap-4 ">
        <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
          <h2 className="text-xl mb-2 font-sans-serif text-center">
            Total Devices
          </h2>
          {loading ? (
            <Loader />
          ) : (
            <p className="text-center text-xl font-bold text-blue-600">
              {totalDevices}
            </p>
          )}
        </div>
        <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
          <h2 className="text-xl mb-2 font-sans-serif text-center">
            Active devices
          </h2>
          {loading ? (
            <Loader />
          ) : (
            <p className="text-center text-xl font-bold text-green-600">
              {activeDevices}
            </p>
          )}
        </div>
        <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
          <h2 className="text-xl mb-2 font-sans-serif text-center">
            Inactive Devices
          </h2>
          {loading ? (
            <Loader />
          ) : (
            <p className="text-center text-xl font-bold text-red-600">
              {inactiveDevices}
            </p>
          )}
        </div>
        {/* User side hidden commented for future implementation*/}
        {/* <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
          <h2 className="text-xl mb-2 font-sans-serif text-center">
            Schedular On/Off
          </h2>
          {loading ? (
            <Loader />
          ) : (
            <p className="text-center text-xl font-bold">
              <span className="text-green-600">{totalScheduleOn}</span> /
              <span className="text-red-600"> {totalScheduleOff}</span>
            </p>
          )}
        </div> */}
      </div>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <div className="flex flex-row justify-between items-center">
            <input
              type="search"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="border border-black rounded p-1 w-full sm:w-auto h-[34px]"
            />

            <div>
              <IconButton
                onClick={handleFilterClick}
                sx={{
                  margin: 2,
                  backgroundColor: filterActive
                    ? "primary.main"
                    : "transparent",
                  color: filterActive ? "white" : "inherit",
                  borderRadius: "20%",
                  padding: "8px",
                  "&:hover": {
                    backgroundColor: filterActive
                      ? "primary.dark"
                      : "rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <TuneOutlinedIcon />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickExport}
                sx={{ margin: 2 }}
              >
                <DownloadIcon />
                Export Data
              </Button>
            </div>
          </div>
          {filterActive ? (
            <div className="flex flex-col sm:flex-row justify-center items-center gap-4 p-4">
              {/* State Dropdown */}
              <FormControl variant="outlined" className="w-full sm:w-80">
                <InputLabel>State</InputLabel>
                <Select
                  value={selectedState}
                  onChange={handleStateChange}
                  label="State"
                  fullWidth
                >
                  {indianStateCityData.states.map((stateObj) => (
                    <MenuItem key={stateObj.name} value={stateObj.name}>
                      {stateObj.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* City Dropdown */}
              <FormControl variant="outlined" className="w-full sm:w-80">
                <InputLabel>City</InputLabel>
                <Select
                  value={selectedCity}
                  onChange={handleCityChange}
                  label="City"
                  fullWidth
                  disabled={!selectedState}
                >
                  {city.map((cityName: any) => (
                    <MenuItem key={cityName} value={cityName}>
                      {cityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" className="w-full sm:w-80">
                <InputLabel>Center</InputLabel>
                <Select
                  value={area}
                  onChange={handleAreaChange}
                  label="Area"
                  fullWidth
                  disabled={!selectedCity}
                >
                  {Array.isArray(allAreas) && allAreas.length > 0 ? (
                    allAreas.map((areaName: any) => (
                      <MenuItem key={areaName} value={areaName}>
                        {areaName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No areas available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          ) : null}
          {loading ? (
            <Loader />
          ) : (
            <div className="h-[73vh] overflow-y-auto">
              <TableContainer>
                <Table
                  className="min-w-750 bg-white"
                  aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHeader />
                  <TableBody className="">
                    {allDevices.map((row: any) => (
                      <TableRow
                        key={row.id}
                        hover
                        className="cursor-pointer transition-all hover:bg-gray-100"
                      >
                        <TableCell
                          className="text-center"
                          style={{ textAlign: "start" }}
                        >
                          <NavLink
                            to={`/dashboard`}
                            state={{
                              deviceId: row.id,
                              deviceName: row.deviceName,
                            }}
                            className="hover:underline"
                          >
                            {row.deviceName}
                          </NavLink>
                        </TableCell>
                        <TableCell
                          className="text-center"
                          style={{ textAlign: "start", padding: "10px" }}
                        >
                          <NavLink
                            to={`/dashboard`}
                            state={{
                              deviceId: row.id,
                              deviceName: row.deviceName,
                            }}
                            className="hover:underline"
                          >
                            {row.deviceAlias}
                          </NavLink>
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{ textAlign: "start" }}
                        >
                          <span
                            className={`text-${
                              row.deviceDataLogs.some(
                                (log: any) =>
                                  log.tagId === 189 && log.value === "ON"
                              )
                                ? "green-600"
                                : "red-600"
                            } 
                                                        ${
                                                          row.deviceDataLogs
                                                            .length > 0 &&
                                                          row.deviceDataLogs.some(
                                                            (log: any) =>
                                                              log.tagId ===
                                                                189 &&
                                                              log.value === "ON"
                                                          )
                                                            ? "bg-green-200"
                                                            : "bg-red-100"
                                                        } 
                                                        rounded-md 
                                                        ${
                                                          row.deviceDataLogs
                                                            .length > 0 &&
                                                          row.deviceDataLogs.some(
                                                            (log: any) =>
                                                              log.tagId ===
                                                                189 &&
                                                              log.value === "ON"
                                                          )
                                                            ? "px-4 py-2"
                                                            : "px-3 py-2"
                                                        }`}
                          >
                            {row.deviceDataLogs.some(
                              (log: any) =>
                                log.tagId === 189 && log.value === "ON"
                            )
                              ? "ON"
                              : "OFF"}
                          </span>
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{ textAlign: "start", padding: "10px" }}
                        >
                          {row.deviceDataLogs.length > 0
                            ? row.deviceDataLogs.find(
                                (log: any) => log.tagId === 191
                              )?.value || "N/A"
                            : "N/A"}
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{ textAlign: "start", padding: "10px" }}
                        >
                          {row.deviceDataLogs.length > 0
                            ? row.deviceDataLogs.find(
                                (log: any) => log.tagId === 188
                              )?.value || "N/A"
                            : "N/A"}
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{ textAlign: "start", padding: "10px" }}
                        >
                          {row.deviceDataLogs.length > 0
                            ? row.deviceDataLogs.find(
                                (log: any) => log.tagId === 190
                              )?.value || "N/A"
                            : "N/A"}
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{
                            textAlign: "start",
                            padding: "10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.scheduling
                            ? `${row.scheduling.schedulerOn} - ${row.scheduling.schedulerOff}`
                            : "N/A"}
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{ textAlign: "start", padding: "10px" }}
                        >
                          <Switch
                            checked={row.scheduling?.triggerStatus ?? false} // Use optional chaining and nullish coalescing
                            color="primary"
                            // sx={{
                            //   '& .MuiSwitch-switchBase': {
                            //     color: row.scheduling?.triggerStatus ? 'green' : 'red',
                            //   },
                            //   '& .MuiSwitch-switchBase.Mui-checked': {
                            //     color: 'green',
                            //   },
                            //   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            //     backgroundColor: 'green',
                            //   },
                            //   '& .MuiSwitch-track': {
                            //     backgroundColor: 'red',
                            //   },
                            // }}
                          />
                          {/* <span 
                            style={{ marginRight: 1, color: row.scheduling?.triggerStatus ? 'green' : 'red' }}
                          >
                            {row.scheduling?.triggerStatus ? 'On' : 'Off'}
                        </span> */}
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{ textAlign: "start" }}
                        >
                          <span
                            className={`text-${
                              row.status ? "green-600" : "red-600"
                            } 
                                                        ${
                                                          row.status
                                                            ? "bg-green-200"
                                                            : "bg-red-100"
                                                        } 
                                                        rounded-md 
                                                        ${
                                                          row.status
                                                            ? "px-4 py-2"
                                                            : "px-3 py-2"
                                                        } `}
                          >
                            {row.status ? "Active" : "Inactive"}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 25, 50]}
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="bg-gray-100"
            labelRowsPerPage="Rows per page:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} of ${count}`
            }
          />
        </Paper>
      </Box>
    </>
  );
}

export default ACUserDashboard;
