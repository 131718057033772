import React, { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EnhancedTableHead from "./EnhancedTableHead";
import { NavLink } from "react-router-dom";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { http } from "../../helper/http";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Loader from "../../components/Loader/Loader";
const baseUrl = process.env.REACT_APP_BASE_URL;

interface Device {
  id: number;
  deviceAlias: string;
  deviceName: string;
  status: boolean;
  latitude: string;
  longitude: string;
}

interface OverviewDeviceResponse {
  devices: Device[];
  onlineDevices: number;
  offlineDevices: number;
}

interface GoogleMapComponentProps {
  devices: Device[];
}

const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({ devices }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC16Rib7of6NTJlwxLP2myOcq-w9sI4-CA",
  });

  const [hoveredDevice, setHoveredDevice] = useState<Device | null>(null);
  const [hoveredDevices, setHoveredDevices] = useState<Device[]>([]);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [mapZoom, setMapZoom] = useState(3);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

  const markers = useMemo(() => {
    return devices.map((device) => ({
      lat: parseFloat(device.latitude),
      lng: parseFloat(device.longitude),
      device: device,
    }));
  }, [devices]);

  useEffect(() => {
    if (isLoaded && markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker));
      setMapCenter(bounds.getCenter().toJSON());
      setMapZoom(getBoundsZoomLevel(bounds));
    }
  }, [isLoaded, markers]);

  const getBoundsZoomLevel = (bounds: google.maps.LatLngBounds) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 16;

    function latRad(lat: number) {
      const sin = Math.sin((lat * Math.PI) / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    const latZoom = zoom(400, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(600, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <div style={{ width: "100%", height: "670px" }}>
      <GoogleMap
        center={mapCenter}
        zoom={mapZoom}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          streetViewControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_LEFT,
          },
        }}
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            onMouseOver={() => {
              setHoveredDevice(marker.device);
              setHoveredDevices(
                devices.filter(
                  (d) =>
                    d.latitude === marker.device.latitude &&
                    d.longitude === marker.device.longitude
                )
              );
            }}
            onMouseOut={() => {
              if (timeoutId) {
                clearTimeout(timeoutId);
              }
              setTimeoutId(
                setTimeout(() => {
                  setHoveredDevice(null);
                  setHoveredDevices([]);
                }, 2000)
              );
            }}
          >
            {hoveredDevice === marker.device && (
              <div
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  position: "absolute",
                  top: "150px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 1,
                  maxWidth: "250px",
                  maxHeight: "300px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {hoveredDevices.map((d, i) => (
                  <div
                    key={i}
                    style={{
                      borderBottom:
                        i < hoveredDevices.length - 1
                          ? "1px solid #ccc"
                          : "none",
                      paddingBottom: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <p>
                      <strong>Device Name:</strong> {d.deviceName}
                    </p>
                    <p>
                      <strong>Status:</strong>{" "}
                      <span style={{ color: d.status ? "green" : "red" }}>
                        {d.status ? "Active" : "Inactive"}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </div>
  );
};

export const Overviewpage: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [combinedDevices, setCombinedDevices] = useState<Device[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const { users }: any = useUserState();

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const [overviewResp] = await Promise.all([
          http(
            `${baseUrl}/overview-dashboard?key=globalOverview`
          ) as Promise<OverviewDeviceResponse>,
        ]);

        const overviewDevices = overviewResp.devices;

        const allDevices = [...overviewDevices];
        const uniqueDevices = Array.from(new Set(allDevices.map((d) => d.id)))
          .map((id) => allDevices.find((d) => d.id === id))
          .filter((d): d is Device => d !== undefined);

        setCombinedDevices(uniqueDevices);
        setTotalItems(uniqueDevices.length);

        // Calculate combined active and inactive counts
        // const activeDevices = uniqueDevices.filter((d) => d.status).length;
        // const inactiveDevices = uniqueDevices.filter((d) => !d.status).length;

        setActiveCount(overviewResp.onlineDevices);
        setInactiveCount(overviewResp.offlineDevices);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchAllData();
  }, [users.user.id]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      combinedDevices.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, combinedDevices]
  );
  

  return (
    <>
      <h1 className="px-2" style={{ fontSize: "28px" }}>
        Overview
      </h1>
      <div className="grid md:grid-cols-2 w-full gap-4 mt-2 px-2">
        <div>
          <div className="flex gap-4 ">
            <div className="bg-white p-4 shadow-sm mb-4 w-full border border-solid border-gray-300">
              <h2 className="text-xl mb-2 font-sans-serif text-center">
                Active devices
              </h2>

              {loading ? (
                <Loader />
              ) : (
                <p className="text-center text-xl font-bold text-green-600">
                  {activeCount}
                </p>
              )}
            </div>
            <div className="bg-white p-4 shadow-md mb-4 w-full border border-solid border-gray-300">
              <h2 className="text-xl font-sans-serif mb-2 text-center">
                Inactive devices
              </h2>

              {loading ? (
                <Loader />
              ) : (
                <p className="text-center text-xl font-bold text-red-600">
                  {inactiveCount}
                </p>
              )}
            </div>
          </div>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Paper sx={{ width: "99%" }}>
              <p className="font-semibold text-xl text-slate-600 mb-4 py-1 px-2">
                Devices
              </p>
              <div className="h-[73vh] overflow-y-auto">
                <TableContainer>
                  <Table
                    className="min-w-750 bg-white"
                    aria-labelledby="tableTitle"
                    size="medium"
                  >
                    <EnhancedTableHead />
                    <TableBody className="">
                      {visibleRows.map((row: Device) => (
                        <TableRow
                          key={row.id}
                          hover
                          className="cursor-pointer transition-all hover:bg-gray-100"
                        >
                          <TableCell
                            className="text-center"
                            style={{ textAlign: "start" }}
                          >
                            <NavLink
                              to={`/dashboard`}
                              state={{
                                deviceId: row.id,
                                deviceName: row.deviceName,
                              }}
                              className="hover:underline"
                            >
                              {row.deviceName}
                            </NavLink>
                          </TableCell>
                          <TableCell
                            className="text-center"
                            style={{ textAlign: "start", padding: "10px" }}
                          >
                            <NavLink
                              to={`/dashboard`}
                              state={{
                                deviceId: row.id,
                                deviceName: row.deviceName,
                              }}
                              className="hover:underline"
                            >
                              {row.deviceAlias}
                            </NavLink>
                          </TableCell>
                          
                          <TableCell
                            className="text-center"
                            style={{ textAlign: "start" }}
                          >
                            <span
                              className={`text-${
                                row.status ? "green-600" : "red-600"
                              } 
                              ${row.status ? "bg-green-200" : "bg-red-100"} 
                              rounded-md 
                              ${row.status ? "px-4 py-2" : "px-3 py-2"} `}
                            >
                              {row.status ? "Active" : "Inactive"}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <TablePagination
                component="div"
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="bg-gray-100"
                labelRowsPerPage="Rows per page:"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} of ${count}`
                }
              />
            </Paper>
          </Box>
        </div>
        <div>
          <div className="bg-white p-1 rounded-md shadow-md mb-4 border border-solid border-gray-300">
            <GoogleMapComponent devices={combinedDevices} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Overviewpage;