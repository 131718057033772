import React, { useEffect, useState } from 'react'
import { http } from '../../helper/http'
import Customers from '../admin/customer/Customers'
import { useNavigate } from 'react-router-dom'
const baseUrl = process.env.REACT_APP_BASE_URL;

const cardContainer: any = {
    display: 'flex',
    flexWrap: 'no-wrap'
}

const card: any = {
    width: '25%',
    marginRight: '24px',
    border: '2px solid lightgrey',
    borderRadius: '16px',
    padding: '20px'
}

const cardTitle: any = {
    fontSize: '18px',
    fontWeight: '600'
}

const cardValue: any = {
    fontSize: '28px',
    fontWeight: '600'
}


interface CardData {
    customerCount: number | string;
    deviceCount: number | string;
    onlineDevices: number | string;
    offlineDevices: number | string;
}

interface SchedulerAlertData {
    message?: string;
    count: number;
}

const AdminDashboard1 = () => {

    const [cardData, setCardData] = useState<CardData>({
        customerCount: '',
        deviceCount: '',
        onlineDevices: '',
        offlineDevices: '',
    });
    const [schedulerAlertCount, setSchedulerAlertCount] = useState<SchedulerAlertData>({
        count: 0,
        message: ''
    });
    const key = 'COUNT';
    const navigate = useNavigate();
    
    async function getCarddData() {
        try {
            const resp = await http('/admin-dashboard') as any;
            setCardData((prevState: any) => ({
                ...prevState,
                customerCount: resp.customerCount,
                deviceCount: resp.deviceCount,
            }));
        } catch(err) {
            console.error(err);
        }
    }

    async function getDeviceCount() {
        try {
            const resp1 = await http('/admin-device-dashboard') as any;
            setCardData((prevState: any) => ({
                ...prevState,
                onlineDevices: resp1.onlineDevices,
                offlineDevices: resp1.offlineDevices,
            }));
        } catch(err) {
            console.error(err);
        }
    }

    async function getSchedulerAlertCount() {
        try {
            const response = await http(`${baseUrl}/alert-log?key=${key}`) as SchedulerAlertData;
            if(response){
                setSchedulerAlertCount({
                    message: response.message,
                    count: response.count
                });
            } else {
                console.error('Invalid response from server');
            }
        } catch(error){
            console.error('Failed to fetch scheduler alert', error);
        }
    }

    const handleSchedulerAlertClick = () => {
        navigate('/scheduleAlert');
    }



    useEffect(() => {
        getCarddData()
        getDeviceCount()
        getSchedulerAlertCount()
    }, []);

  return (
    <> 
        <div style={cardContainer}>
            <div style={card}>
                <p style={cardTitle}>Customer Count</p>
                <p style={cardValue}>{cardData.customerCount}</p>
            </div>
            <div style={card}>
                <p style={cardTitle}>Device Count</p>
                <p style={cardValue}>{cardData.deviceCount}</p>
            </div>
            <div style={card}>
                <p style={cardTitle}>Online Devices</p>
                <p style={cardValue}>{cardData.onlineDevices}</p>
            </div>
            <div style={card}>
                <p style={cardTitle}>Offline Devices</p>
                <p style={cardValue}>{cardData.offlineDevices}</p>
            </div>
            {/* #TODO for future implementation */}
            <div className='cursor-pointer' style={card} onClick={handleSchedulerAlertClick}>
                <p style={cardTitle}>Scheduler Alert</p>
                <p style={cardValue}>{schedulerAlertCount.count}</p>
            </div>
        </div>
        <br /><br />
        <Customers />
    </>
  )
}

export default AdminDashboard1