import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./LineChart.css";
import customRestIcon from "../../images/refresh.svg";

interface LineData {
  name?: string;
  color: string;
  showDots?: number;
  data: { timestamp: string; value: string | number }[];
  showHoverTooltip?: boolean;
  postfix?: string;
}

interface LineChartProps {
  data: LineData[];
  xAxisName?: string;
}

const LineChartCustomise: React.FC<LineChartProps> = ({ data, xAxisName }) => {
  // console.log("daattaaaa from chart", data);
  
  const now = new Date();
  const thirtyMinutes = 90 * 60 * 1000;
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

  const series = data.map((line) => {
    let lastTimestamp: number | null = null;

    const processedData = line.data.flatMap((point) => {
      const pointTime = new Date(point.timestamp).getTime();
      let value: number | null = null;

      if (typeof point.value === 'string') {
        if (line.name === "Status" || line.name === "Trigger Status") {
          value = point.value === 'ON' ? 1 : point.value === 'OFF' ? 0 : 0;
        } else {
          value = parseFloat(point.value);
        }
      } else {
        value = point.value as number;
      }

      const result = [];
      if (lastTimestamp !== null && pointTime - lastTimestamp > thirtyMinutes) {
        result.push({ x: pointTime - thirtyMinutes, y: null });
      }
      result.push({ x: pointTime, y: value });
      lastTimestamp = pointTime;
      return result;
    });

    return {
      name: line.name || "",
      data: processedData,
      color: line.color,
      showDots: line.showDots || 0,
      postfix: line.postfix,
      yAxisIndex: line.name === "Status" || line.name === "Trigger Status" ? 2 : (line.name === "Humidity" ? 1 : 0),
    };
  });

  const allValues = series.flatMap(line => line.data.map(point => point.y)).filter(y => y !== null) as number[];

  const minY = 0;
  const maxY = allValues.length > 0 ? Math.max(...allValues) + 20 : 100;

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: 'xy',
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: `<img src=${customRestIcon} width="20" alt="Reset Zoom">`,
        },
      },
    },
    stroke: {
      width: [3, 3, 2, 2],
      dashArray: [0, 0, 0, 0],
      curve: series.map(line => (line.name === "Status" || line.name === "Trigger Status") ? 'stepline' : 'smooth'),
    },
    xaxis: {
      type: 'datetime',
      min: twentyFourHoursAgo.getTime(),
      max: now.getTime(),
      title: {
        text: xAxisName,
      },
      labels: {
        datetimeUTC: false,
        format: 'HH:mm',
      },
      tickAmount: 6,
    },
    yaxis: [
      {
        title: {
          text: 'Temperature',
        },
        min: minY,
        max: maxY,
      },
      {
        title: {
          text: 'Humidity',
        },
        opposite: true,
        min: minY,
        max: maxY,
      },
      {
        title: {
          text: 'Status',
        },
        opposite: true,
        min: 0,
        max: 1,
        tickAmount: 1,
        labels: {
          formatter: function (value: number) {
            return value === 1 ? 'ON' : 'OFF';
          }
        },
      }
    ],
    tooltip: {
      x: {
        formatter: function(val: any) {
          return new Date(val).toLocaleString();
        }
      },
      y: {
        formatter: function (value, { seriesIndex }) {
          const seriesName = data[seriesIndex].name || '';
          if (seriesName === "Status" || seriesName === "Trigger Status") {
            return value === 1 ? 'ON' : value === 0 ? 'OFF' : '';
          }
          const postfix = data[seriesIndex].postfix || '';
          return value === null ? "N/A" : value + " " + postfix;
        },
      }
    },
    markers: {
      size: series.map((line) => (line.showDots ? 4 : 0)),
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    colors: series.map((line) => line.color),
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={350}
      key={JSON.stringify(data)}
    />
  );
};

export default LineChartCustomise;
