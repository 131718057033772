import { useState } from "react";
import { useDarkMode } from "../../utils/darkMode/DarkModeContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { http } from "../../helper/http";
import { login } from "../../app-context/auth-context/AuthAction";
import { toast } from "react-toastify";
import { ApiResponse } from "../../types/UserTypes";
import logo from "../../images/Awfis-new-logo.png";
import LinearIndeterminate from "../../ui/LinearLoader";
import BackgroundImage from "../../images/Awfis-Background.png"

export default function Login() {
  const { darkMode } = useDarkMode();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const { dispatch } = useUserState();

  const submitForm = async (e: any) => {
    e.preventDefault();
    const data = { email, password };

    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const resp = (await http(
        process.env.REACT_APP_BASE_URL + "/auth",
        "POST",
        data
      )) as ApiResponse;

      const token = resp.token;
      const user = resp.user;

      login({ dispatch, token, user });
      setSubmitting(false);
    } catch (err: any) {
      console.log(err);

      toast.error(err);
    }
    setSubmitting(false);
  };

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formContainerStyles = {
    backgroundColor: darkMode ? "#333" : "#fff",
    color: darkMode ? "#fff" : "#000",
    backgroundImage: `url(${BackgroundImage})`,
  };

  const formStyles = {
    backgroundColor: darkMode ? "#333" : "#fff",
  };

  return (
    <>
      <div style={formContainerStyles}>
        {submitting && <LinearIndeterminate />}
        <div
          style={formContainerStyles}
          className="font-roboto h-screen flex items-center justify-center"
        >
          <form
            onSubmit={submitForm}
            style={formStyles}
            className={
              darkMode
                ? "max-w-md w-full rounded p-[2rem]"
                : "max-w-md w-full rounded p-[2rem]"
            }
            action="#"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={logo}
                alt="Company Logo"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "200px",
                }}
              />
            </div>
            <input
              type="text"
              placeholder="username "
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
              className={
                darkMode
                  ? "h-10 w-full border border-gray-300 rounded mb-4 px-4 text-base focus:outline-none focus:border-blue-500 text-black"
                  : "h-10 w-full border border-gray-300 rounded mb-4 px-4 text-base focus:outline-none focus:border-blue-500"
              }
            />
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              className={
                darkMode
                  ? "h-10 w-full border border-gray-300 rounded mb-4 px-4 text-base focus:outline-none focus:border-blue-500 text-black"
                  : "h-10 w-full border border-gray-300 rounded mb-4 px-4 text-base focus:outline-none focus:border-blue-500"
              }
            />
            {showPassword ? (
              <VisibilityOff
                onClick={handlePasswordVisibility}
                style={{
                  marginLeft: "-10%",
                  marginBottom: "2%",
                  color: darkMode ? "#000" : "#000",
                }}
              />
            ) : (
              <Visibility
                onClick={handlePasswordVisibility}
                style={{
                  marginLeft: "-10%",
                  marginBottom: "2%",
                  color: darkMode ? "#000" : "#000",
                }}
              />
            )}
            <div className="flex flex-col text-center gap-4">
              <button
                disabled={submitting}
                type="submit"
                className="bg-blue-900 text-white h-12 rounded font-semibold text-lg transition duration-200 hover:bg-teal-600 focus:outline-none"
              >
                Login
              </button>
              {/* <Link to="/forgot-password" className="text-teal-500 text-sm hover:underline">
                Forgot password?
              </Link> */}
            </div>
            {/* <hr className="my-8 border-t border-gray-300" /> */}
            {/* <div className="text-center mt-6">
              <Link to="/register" className="px-4 py-3 bg-gray-800 text-white rounded  font-semibold text-base transition duration-200 hover:bg-gray-600">
                Create new account
              </Link>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
}
