import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./LineChart.css";
import customRestIcon from "../../images/refresh.svg";

interface LineData {
  name?: string;
  color: string;
  data: { timestamp: string; value: string | number }[];
  postfix?: string;
}

interface LineChartProps {
  data: LineData[];
  xAxisName?: string;
}

const DualAxisLineChart: React.FC<LineChartProps> = ({ data, xAxisName }) => {
  // Extract unique timestamps across all series
  const categories = Array.from(
    new Set(data.flatMap((line) => line.data.map((point) => point.timestamp)))
  )
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .map((timestamp) => {
      const date = new Date(timestamp);
      return `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    });

  // Map series data
  const series = data.map((line) => {
    const dataMap = new Map(
      line.data.map((point) => [
        point.timestamp,
        typeof point.value === "string"
          ? line.name === "Buzzer Status"
            ? point.value === "ON"
              ? 1
              : 0
            : parseFloat(point.value)
          : point.value,
      ])
    );

    const alignedData = categories.map((_, index) => {
      const timestamp = data[0].data[index]?.timestamp;
      return dataMap.get(timestamp) ?? null;
    });

    return {
      name: line.name || "",
      data: alignedData,
      color: line.color,
    };
  });

  // Dual-axis options
  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: `<img src=${customRestIcon} width="20" alt="Reset Zoom">`,
        },
      },
    },
    xaxis: {
      categories,
      title: {
        text: xAxisName,
      },
    },
    yaxis: [
      {
        title: {
          text: "Temperature (℃)",
        },
      },
      {
        title: {
          text: "Humidity (%)",
        },
        opposite: true,
      },
      {
        title: {
          text: "Buzzer Status",
        },
        opposite: true,
        min: 0,
        max: 1,
        tickAmount: 1,
        labels: {
          formatter: (value) => (value === 1 ? "ON" : "OFF"),
        },
      },
    ],
    tooltip: {
      x: {
        formatter: (_, opts) => {
          const index = opts.dataPointIndex;
          return data[0]?.data[index]?.timestamp || "";
        },
      },
      y: {
        formatter: (value, { seriesIndex }) => {
          const postfix = data[seriesIndex]?.postfix || "";
          if (seriesIndex === 2) return value === 1 ? "ON" : "OFF";
          return `${value.toFixed(2)} ${postfix}`;
        },
      },
    },
    colors: series.map((line) => line.color),
    markers: {
      size: 4,
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    stroke: {
      width: [2, 2, 3], // Different stroke width for buzzer
      curve: ["smooth", "smooth", "stepline"], // Apply step line for buzzer
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={350}
    />
  );
};

export default DualAxisLineChart;
