
// import React from "react";
// import ReactApexChart from "react-apexcharts";
// import { ApexOptions } from "apexcharts";
// import "./LineChart.css";
// import customRestIcon from "../../images/refresh.svg"

// interface LineData {
//   name?: string;
//   color: string;
//   showDots?: number;
//   data: { timestamp: string; value: number }[];
//   showHoverTooltip?: boolean;
//   postfix?: string;
// }


// interface LineChartProps {
//   data: LineData[];
//   yAxisName?: string;
//   stroke?: Boolean;
//   xAxisName?: string;
// }

// const LineChart: React.FC<LineChartProps> = ({ data, yAxisName, xAxisName, stroke }) => {
  
//   const now = new Date();
//   const thirtyMinutes = 30 * 60 * 1000;
  

//   const series = data.map((line) => {
//     let lastTimestamp: number | null = null;

//     const processedData = line.data.flatMap((point) => {
//       const pointTime = new Date(point.timestamp).getTime();
//       const result = [];
//       if (lastTimestamp !== null && pointTime - lastTimestamp > thirtyMinutes) {
//         result.push({ x: pointTime - thirtyMinutes, y: null });        
//       }
//       result.push({ x: pointTime, y: point.value });
//       lastTimestamp = pointTime;
//       return result;
//     });    

//     return {
//       name: line.name || "",
//       data: processedData,
//       color: line.color,
//       showDots: line.showDots,
//       postfix: line.postfix,
//     };
//   });

//   // const now = new Date();
//   const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

//   const options: ApexOptions = {
//     chart: {
//       type: "line",
//       height: 350,
//       animations: {
//         enabled: false,
//       },
//       zoom: {
//         enabled: true,
//         type: 'xy',
//         autoScaleYaxis: true,
//       },
//       toolbar: {
//         show: true,
//         offsetX: 0,
//         offsetY: 0,
//         tools: {
//           download: false,
//           selection: false,
//           zoom: true,
//           zoomin: false,
//           zoomout: false,
//           pan: false,
//           reset: `<img src=${customRestIcon} width="20" alt="Reset Zoom">`,
//         },
//       },
//     },
//     xaxis: {
//       type: 'datetime',
//       min: twentyFourHoursAgo.getTime(),
//       max: now.getTime(),
//       title: {
//         text: xAxisName,
//       },
//       labels: {
//         datetimeUTC: false,
//         format: 'HH:mm',
//       },
//       tickAmount: 6,
//     },
//     yaxis: {
//       title: {
//         text: yAxisName,
//       },
//       labels: {
//         formatter: function (value: any) {
//          // console.log('value',value)
//           return value;
//         }
//       }
//     },
//     tooltip: {
//       x: {
//         formatter: function(val: any) {
//           return new Date(val).toLocaleString();
//         }
//       },
//       y: {
//         formatter: function (value, { seriesIndex }) {
//           const postfix = data[seriesIndex].postfix || "";
//           // console.log("value from cahrt", value);
          
//           return value === null ? "N/A" : value+" " + postfix;
//         },
//       }
//     },
//     markers: {
//       size: series.map((line) => (line.showDots ? 4 : 0)),
//       strokeWidth: 0,
//       hover: {
//         size: 8,
//       },
//     },
//     colors: series.map((line) => line.color),
//     ...(stroke
//       ? {
//           stroke: {
//             width: 2,
//             curve: "smooth",
//           },
//         }
//       : {}),
//   };

//   return (
//     <ReactApexChart
//       options={options}
//       series={series}
//       type="line"
//       height={350}
//       key={JSON.stringify(data)}
//     />
//   );
// };

// export default LineChart;

import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./LineChart.css";
import customRestIcon from "../../images/refresh.svg"

interface LineData {
  name?: string;
  color: string;
  showDots?: number;
  data: { timestamp: string; value: number }[];
  showHoverTooltip?: boolean;
  postfix?: string;
}

interface LineChartProps {
  data: LineData[];
  yAxisName?: string;
  stroke?: Boolean;
  xAxisName?: string;
}

const LineChart: React.FC<LineChartProps> = ({ data, yAxisName, xAxisName, stroke }) => {

  const series = data.map((line) => ({
    name: line.name || "",
    data: line.data.map((point) => point.value),
    color: line.color,
    showDots: line.showDots,
    postfix: line.postfix,
    strokeWidth: 1,
    curve: "smooth",
  }));

  const categories = data.length > 0
    ? data[0].data.map((point) => {
        const date = new Date(point.timestamp);
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      })
    : [];

  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: `<img src=${customRestIcon} width="20" alt="Reset Zoom">`,
        },
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: xAxisName,
      },
    },
    yaxis: {
      title: {
        text: yAxisName,
      },
      labels: {
        formatter: function (value: any) {
          return parseFloat(value).toFixed(3);
        }
      }
    },
    tooltip: {
      x: {
        formatter: function(val: any, opts: any) {
          const index = opts.dataPointIndex;
          const timestamp = data[0].data[index].timestamp;
          return timestamp;
        }
      },
      y: {
        formatter: function (value, { seriesIndex }) {
          const postfix = data[seriesIndex].postfix || "";
          return value.toFixed(3) + postfix;
        },
      }
    },
    markers: {
      size: series.map((line) => (line.showDots ? 4 : 0)),
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    colors: series.map((line) => line.color),
    series: series,
    ...(stroke
      ? {
          stroke: {
            width: 2,
            curve: "smooth",
          },
        }
      : {}),
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={350}
    />
  );
};

export default LineChart;