import { useEffect, useState } from "react";
import { http } from "../../../helper/http";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormLabel,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL;
const CustomTags = () => {
  const [tags, setTags] = useState([
    {
      tagId: "",
      tagName: "",
      sensorName: "",
      scalingFactor: "",
      sensorStatus: false,
      sensorUnit: "",
      sensorAlias:"",
      displayTagStatus: false
    },
  ]);
  const [tagsId, setTagsId] = useState("");
  const navigate = useNavigate();
  const { id, template } = useParams();

  const handleCancel = () => {
    navigate("/device");
  };

  const handleUpdate = async () => {
    const enabledAnalysisCount = tags.filter((tag) => tag.displayTagStatus).length;
    if (enabledAnalysisCount > 4) {
      toast.error("You can enable a maximum of 4 Analysis UI Tags");
      return;
    }
    const body = {
      deviceId: id,
      tagJson: tags.map((tag) => ({
        tagId: tag.tagId,
        tagName: tag.tagName,
        sensorName: tag.sensorName,
        scalingFactor: tag.scalingFactor,
        sensorStatus: tag.sensorStatus,
        sensorUnit: tag.sensorUnit,
        sensorAlias: tag.sensorAlias,
        displayTagStatus: tag.displayTagStatus,
      })),
    };

    try {
      await http(`${baseUrl}/device-template?id=${tagsId}`, "PUT", body);
      toast.success("Tags Updated Successfully");
    } catch (error) {
      toast.error("Tags Failed to Update");
    }
  };

  const handleReset = async () => {
    const response: any = await http(
      `${baseUrl}/tag-groups?templateId=${template}`
    );
    if (response.tags && response.tags.length > 0) {
      setTags(
        response.tags.map((tag: any) => ({
          tagId: tag.tagId,
          tagName: tag.tagName,
          sensorName: tag.sensorName,
          scalingFactor: tag.scalingFactor,
          sensorStatus: tag.sensorStatus,
          sensorUnit: tag.sensorUnit,
          sensorAlias: tag.sensorAlias,
          displayTagStatus: tag.displayTagStatus,
        }))
      );
    }
  };

  const fetchDetails = async () => {
    try {
      const response: any = await http(
        `${baseUrl}/device-template?deviceId=${id}`
      );
      setTagsId(response.id);
      if (response.tagJson && response.tagJson.length > 0) {
        setTags(
          response.tagJson.map((tag: any) => ({
            tagId: tag.tagId,
            tagName: tag.tagName,
            sensorName: tag.sensorName,
            scalingFactor: tag.scalingFactor,
            sensorStatus: tag.sensorStatus,
            sensorUnit: tag.sensorUnit,
            sensorAlias: tag.sensorAlias,
            displayTagStatus: tag.displayTagStatus,
          }))
        );
      } else {
        setTags([
          {
            tagId: "",
            tagName: "",
            sensorName: "",
            scalingFactor: "",
            sensorStatus: false,
            sensorUnit: "",
            sensorAlias: "",
            displayTagStatus: false
          },
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log("tags data after response", tags);

  useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [id]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "90%",
            marginBottom: "30px",
            border: "1px solid #979797",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
              backgroundColor: "#979797",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                textAlign: "center",
                color: "white",
              }}
            >
              Edit Custom Tag Names
            </p>
          </div>
          {tags.map((tag, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "5px",
              }}
            >
              <TextField
                label="Tag Name"
                value={tag.tagName}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                  sx: {
                    "&:hover": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "1.25rem",
                  },
                }}
              />
              <TextField
                label="Sensor Name"
                value={tag.sensorName}
                onChange={(e) => {
                  const newTags = [...tags];
                  newTags[index].sensorName = e.target.value;
                  setTags(newTags);
                }}
                fullWidth
                margin="normal"
                style={{ marginLeft: "10px" }}
              />

              <TextField
                label="Scaling Factor"
                value={tag.scalingFactor}
                onChange={(e) => {
                  const newTags = [...tags];
                  newTags[index].scalingFactor = e.target.value;
                  setTags(newTags);
                }}
                fullWidth
                margin="normal"
                style={{ marginLeft: "10px" }}
              />
              <div
                style={{
                  width: "38rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <FormLabel>Sensor Status</FormLabel>
                <Switch
                  checked={
                    tag.sensorStatus !== undefined ? tag.sensorStatus : false
                  }
                  color="primary"
                  onChange={() => {
                    const newTags = [...tags];
                    newTags[index].sensorStatus = !newTags[index].sensorStatus;
                    setTags(newTags);
                  }}
                />
              </div>
              <TextField
                label="Sensor Alias"
                value={tag.sensorAlias}
                onChange={(e) => {
                  const newTags = [...tags];
                  newTags[index].sensorAlias = e.target.value;
                  setTags(newTags);
                }}
                fullWidth
                margin="normal"
                style={{ marginLeft: "10px" }}
              />
              <TextField
                label="Sensor Unit"
                value={tag.sensorUnit}
                onChange={(e) => {
                  const newTags = [...tags];
                  newTags[index].sensorUnit = e.target.value;
                  setTags(newTags);
                }}
                fullWidth
                margin="normal"
                style={{ marginLeft: "10px" }}
              />
              <div
                style={{
                  width: "38rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <FormLabel>Analysis UI</FormLabel>
                <Switch
                  checked={
                    tag.displayTagStatus !== undefined ? tag.displayTagStatus : false
                  }
                  color="primary"
                  onChange={() => {
                    const newTags = [...tags];
                    newTags[index].displayTagStatus = !newTags[index].displayTagStatus;
                    setTags(newTags);
                  }}
                />
              </div>
            </div>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
          >
            <Button
              onClick={handleReset}
              color="success"
              style={{ marginRight: "10px" }}
            >
              Reset
            </Button>
            <Button
              onClick={handleCancel}
              color="secondary"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Update
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTags;
