import React, { useCallback, useEffect, useRef, useState } from "react";

// import "../../App.css";
import {
  formatDate,
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../../common-function/CommonFunction";

import LineChart from "../../../components/apex-chart/LineChart";
import CardThree from "../../../components/dashBoardComponents/CardThree";
import LongMenu from "../../../utils/longMenu/LongMenu";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import { http } from "../../../helper/http";
import {
  fillMissingDays,
  fillMissingHours,
  filterDataFromEachDayEnergy,
  filterDataFromEachHourEnergy,
  processConsumptionDataEnergy,
  processHourlyDataEnergy,
  processHourlyDataEnergyDaily,
  processMonthlyConsumptionEnergy,
} from "./common-functions";
import CircularProgress from "@mui/material/CircularProgress";
import DynamicBarChart from "../../../components/apex-chart/CommonBarChart2";
import NormalDynamicLineChart from "../../../components/apex-chart/NormalDynamicLineChart";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardAwfisEnergy = ({ devices, selectedDevice }: any) => {
  //material tabs
  const [value, setValue] = React.useState(0);
  const [valueAnalysis, setvalueAnalysis] = React.useState(0);
  //instantenious
  const [valueInst, setInst] = React.useState(0);
  const [valueInstPower, setvalueInstPower] = React.useState(0);
  const [valueInstDemand, setvalueInstDemand] = React.useState(0);
  const [valueInstTDH, setvalueInstTDH] = React.useState(0);
  const [valueInstVolt, setValueInstVolt] = React.useState(0);

  const [apiData, setApiData] = useState<any>([]); //setTemperature
  const barchartref1 = useRef<HTMLDivElement>(null);
  const barchartref2 = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);
  const chartRef4 = useRef<HTMLDivElement>(null);
  const chartRef5 = useRef<HTMLDivElement>(null);
  const chartRef6 = useRef<HTMLDivElement>(null);
  const chartRef7 = useRef<HTMLDivElement>(null);
  const chartRef8 = useRef<HTMLDivElement>(null);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const [loader, setLoader] = React.useState(false);
  const [customTagData, setCustomTagData] = useState<any>([]);
  // console.log("custom tag data", customTagData);

  const fetchData = async () => {
    let dof = new Date();

    let response: any = null;
    try {
      const formattedDate = formatDate(dof);
      let queryParam = "";
      response = await http(
        `${baseUrl}/device-data-log?deviceId=${selectedDevice}&last24=last24`
      );
      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),

            // analysis tab
            averagevoltage: d["a_v"],
            averagecurrent: d["a_cl"],
            averagepf: d["a_pf"],
            totalactivepower: d["t_kw"],
            totalreactivepower: d["t_kv"],
            totalapparentpower: d["t_kva"],

            //Voltage tab
            voltage1: d["v1"],
            voltage2: d["v2"],
            voltage3: d["v3"],

            //current Tab
            current1: d["cl1"],
            current2: d["cl2"],
            current3: d["cl3"],

            //Power Tab
            activepower1: d["kw1"],
            activepower2: d["kw2"],
            activepower3: d["kw3"],

            reactivepower1: d["kv1"],
            reactivepower2: d["kv2"],
            reactivepower3: d["kv3"],

            apparentpower1: d["kva1"],
            apparentpower2: d["kva2"],
            apparentpower3: d["kva3"],

            //Power Factor Tab
            powerfactor1: d["pf1"],
            powerfactor2: d["pf2"],
            powerfactor3: d["pf3"],

            //Frequency Tab
            frequency: d["freq"],

            //Treshold Tab
            rvtgphthd: d["thd_v1n"],
            yvtgphthd: d["thd_v2n"],
            bvtgphthd: d["thd_v3n"],

            ryvtgphthd: d["thd_v12"],
            ybvtgphthd: d["thd_v23"],
            rbvtgphthd: d["thd_v31"],

            rcrtphthd: d["thd_ci1"],
            ycrtphthd: d["thd_ci2"],
            bcrtphthd: d["thd_ci3"],

            //Demand Tab
            mxactpower: d["ap_maxd"],
            minactpower: d["ap_mind"],
            mxreactpower: d["rp_maxd"],
            minreactpower: d["rp_mind"],
            mxapppopower: d["apr_maxd"],

            //Others Tab
            runhours: d["r_hour"],
            currintrrpt: d["a_intrpt"],

            rphvoltage: d["max_iv1n"],
            yphvoltage: d["max_iv2n"],
            bphvoltage: d["max_iv3n"],

            ryphvoltage: d["max_iv12"],
            ybphvoltage: d["max_iv23"],
            rbphvoltage: d["max_iv31"],

            rphcuurent: d["max_ici1"],
            yphcurrent: d["max_ici2"],
            bphcurrent: d["max_ici3"],
            //RSSID
            rssi: d["rssi"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setDashboardSts(_data.length ? _data[0] : "");
      const sortedData = _data.sort((a: any, b: any) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });
      setApiData(sortedData);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // console.log("device ddetails", customTagData);

  useEffect(() => {
    fetchData();
    if (devices?.DeviceTemplate?.tagJson) {
      const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
        (acc: any, tag: any) => {
          acc[tag.tagName] = tag;
          return acc;
        },
        {}
      );
      setCustomTagData(transformedTagJson);
    }
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice, valueAnalysis, value]);

  let energy_data_pdfData: any = [];
  let average_voltage_last24hour: any = [];
  let average_current_last24hour: any = [];
  let average_pf_last24hour: any = [];
  let total_activepower_last24hour: any = [];
  let total_apparentpower_last24hour: any = [];
  let total_reactivepower_last24hour: any = [];

  let voltage1_last24hour: any = [];
  let voltage2_last24hour: any = [];
  let voltage3_last24hour: any = [];
  let avarage_L_L_data_of_current_month: any = [];
  let ry_phase_Voltage_of_current_month: any = [];
  let yb_phase_Voltage_of_current_month: any = [];
  let rb_phase_Voltage_of_current_month: any = [];

  let total_Current_last24hour: any = [];
  let current1_last24hour: any = [];
  let current2_last24hour: any = [];
  let current3_last24hour: any = [];
  let total_Active_power_last24hour: any = [];
  let activePower1_last24hour: any = [];
  let activePower2_last24hour: any = [];
  let activePower3_last24hour: any = [];
  let total_ReactivePower_last24hour: any = [];
  let reactivePower1_last24hour: any = [];
  let reactivePower2_last24hour: any = [];
  let reactivePower3_last24hour: any = [];
  let total_Apparent_Power_last24hour: any = [];
  let apparentPower1_last24hour: any = [];
  let apparentPower2_last24hour: any = [];
  let apparentPower3_last24hour: any = [];
  let averagePowerFactor_last24hour: any = [];
  let powerFactor1_last24hour: any = [];
  let powerFactor2_last24hour: any = [];
  let powerFactor3_last24hour: any = [];
  let frequency_for_pdf: any = [];
  let frequency_of_last24hour: any = [];

  let max_active_demand_of_current_month: any = [];
  let max_reactive_demand_of_current_month: any = [];
  let max_apparend_demand_of_current_month: any = [];

  let min_active_demand_of_current_month: any = [];
  let min_reactive_demand_of_current_month: any = [];

  let r_phase_THD_Voltage_of_current_month: any = [];
  let y_phase_THD_Voltage_of_current_month: any = [];
  let b_phase_THD_Voltage_of_current_month: any = [];
  let ry_phase_THD_Voltage_of_current_month: any = [];
  let yb_phase_THD_Voltage_of_current_month: any = [];
  let rb_phase_THD_Voltage_of_current_month: any = [];
  let r_phase_THD_Current_of_current_month: any = [];
  let y_phase_THD_Current_of_current_month: any = [];
  let b_phase_THD_Current_of_current_month: any = [];

  let intLNVoltPdfData: any = [];
  let intCurrPdfData: any = [];
  let getaveragepfData: any = [];
  let getTDHDataToExport: any = [];

  if (value === 10) {
    if (apiData.length > 0) {
      apiData.forEach(
        ({
          timestamp,
          averagevoltage,
          averagecurrent,
          averagepf,
          totalactivepower,
          totalapparentpower,
          totalreactivepower,
        }: any) => {
          energy_data_pdfData.push({
            timestamp,
            // Average Voltage
            ...(customTagData?.["a_v"]?.sensorStatus
              ? {
                [`${customTagData["a_v"]?.sensorAlias} ${customTagData["a_v"]?.sensorUnit || "kWh"
                  }`]: averagevoltage,
              }
              : !customTagData?.["a_v"]
                ? { "Average Voltage (kWh)": averagevoltage }
                : {}),

            // Average Current
            ...(customTagData?.["a_cl"]?.sensorStatus
              ? {
                [`${customTagData["a_cl"]?.sensorAlias} ${customTagData["a_cl"]?.sensorUnit || "kVarh"
                  }`]: averagecurrent,
              }
              : !customTagData?.["a_cl"]
                ? { "Average Current (kVarh)": averagecurrent }
                : {}),

            // Average PF
            ...(customTagData?.["a_pf"]?.sensorStatus
              ? {
                [`${customTagData["a_pf"]?.sensorAlias} ${customTagData["a_pf"]?.sensorUnit || "kVah"
                  }`]: averagepf,
              }
              : !customTagData?.["a_pf"]
                ? { "Average PF (kVah)": averagepf }
                : {}),

            // Total Active Power
            ...(customTagData?.["t_kw"]?.sensorStatus
              ? {
                [`${customTagData["t_kw"]?.sensorAlias} ${customTagData["t_kw"]?.sensorUnit || "kWh"
                  }`]: totalactivepower,
              }
              : !customTagData?.["t_kw"]
                ? { "Total Active Power (kWh)": totalactivepower }
                : {}),

            // Total Apparent Power
            ...(customTagData?.["exp_ae"]?.sensorStatus
              ? {
                [`${customTagData["exp_ae"]?.sensorAlias} ${customTagData["exp_ae"]?.sensorUnit || "kWh"
                  }`]: totalapparentpower,
              }
              : !customTagData?.["exp_ae"]
                ? { "Total Apparent Power (kWh)": totalapparentpower }
                : {}),

            // Total Reactive Power
            ...(customTagData?.["imp_re"]?.sensorStatus
              ? {
                [`${customTagData["imp_re"]?.sensorAlias} ${customTagData["imp_re"]?.sensorUnit || "kVarh"
                  }`]: totalreactivepower,
              }
              : !customTagData?.["imp_re"]
                ? { "Total Reactive Power (kVarh)": totalreactivepower }
                : {}),
          });

          average_voltage_last24hour.push({
            timestamp,
            value:
              averagevoltage === undefined || averagevoltage === ""
                ? 0
                : averagevoltage,
          });
          average_current_last24hour.push({
            timestamp,
            value:
              averagecurrent === undefined || averagecurrent === ""
                ? 0
                : averagecurrent,
          });

          average_pf_last24hour.push({
            timestamp,
            value:
              averagepf === undefined || averagepf === ""
                ? 0
                : averagepf,
          });

          total_activepower_last24hour.push({
            timestamp,
            value:
              totalactivepower === undefined || totalactivepower === ""
                ? 0
                : totalactivepower,
          });

          total_apparentpower_last24hour.push({
            timestamp,
            value:
              totalapparentpower === undefined || totalapparentpower === ""
                ? 0
                : totalapparentpower,
          });

          total_reactivepower_last24hour.push({
            timestamp,
            value:
              totalreactivepower === undefined || totalreactivepower === ""
                ? 0
                : totalreactivepower,
          });
        }
      );
    }
  } else {
    if (apiData.length > 0) {
      apiData.forEach(
        ({
          timestamp,
          voltage1,
          voltage2,
          voltage3,
          averagecurrent,
          current1,
          current2,
          current3,
          totalactivepower,
          activepower1,
          activepower2,
          activepower3,
          totalreactivepower,
          reactivepower1,
          reactivepower2,
          reactivepower3,
          totalapparentpower,
          apparentpower1,
          apparentpower2,
          apparentpower3,
          averagepf,
          powerfactor1,
          powerfactor2,
          powerfactor3,
          frequency,
          mxactpower,
          minactpower,
          mxreactpower,
          minreactpower,
          mxapppopower,
          rvtgphthd,
          yvtgphthd,
          bvtgphthd,
          ryvtgphthd,
          ybvtgphthd,
          rbvtgphthd,
          rcrtphthd,
          ycrtphthd,
          bcrtphthd,
          averagevoltage
        }: any) => {
          voltage1_last24hour.push({
            timestamp,
            value:
              isNaN(voltage1) || voltage1 === undefined ? 0 : voltage1,
          });

          voltage2_last24hour.push({
            timestamp,
            value: isNaN(voltage2) || voltage2 === undefined ? 0 : voltage2,
          });

          voltage3_last24hour.push({
            timestamp,
            value: isNaN(voltage3) || voltage3 === undefined ? 0 : voltage3,
          });

          total_Current_last24hour.push({
            timestamp,
            value: isNaN(averagecurrent) || averagecurrent === undefined ? 0 : averagecurrent,
          });

          current1_last24hour.push({
            timestamp,
            value: isNaN(current1) || current1 === undefined ? 0 : current1,
          });

          current2_last24hour.push({
            timestamp,
            value: isNaN(current2) || current2 === undefined ? 0 : current2,
          });

          current3_last24hour.push({
            timestamp,
            value: isNaN(current3) || current3 === undefined ? 0 : current3,
          });

          total_Active_power_last24hour.push({
            timestamp,
            value:
              isNaN(totalactivepower) || totalactivepower === undefined ? 0 : totalactivepower,
          });

          activePower1_last24hour.push({
            timestamp,
            value: isNaN(activepower1) || activepower1 === undefined ? 0 : activepower1,
          });

          activePower2_last24hour.push({
            timestamp,
            value: isNaN(activepower2) || activepower2 === undefined ? 0 : activepower2,
          });

          activePower3_last24hour.push({
            timestamp,
            value: isNaN(activepower3) || activepower3 === undefined ? 0 : activepower3,
          });

          total_ReactivePower_last24hour.push({
            timestamp,
            value:
              isNaN(totalreactivepower) || totalreactivepower === undefined
                ? 0
                : totalreactivepower,
          });

          reactivePower1_last24hour.push({
            timestamp,
            value:
              isNaN(reactivepower1) || reactivepower1 === undefined ? 0 : reactivepower1,
          });

          reactivePower2_last24hour.push({
            timestamp,
            value:
              isNaN(reactivepower2) || reactivepower2 === undefined ? 0 : reactivepower2,
          });

          reactivePower3_last24hour.push({
            timestamp,
            value:
              isNaN(reactivepower3) || reactivepower3 === undefined ? 0 : reactivepower3,
          });

          total_Apparent_Power_last24hour.push({
            timestamp,
            value:
              isNaN(totalapparentpower) || totalapparentpower === undefined ? 0 : totalapparentpower,
          });

          apparentPower1_last24hour.push({
            timestamp,
            value: isNaN(apparentpower1) || apparentpower1 === undefined ? 0 : apparentpower1,
          });

          apparentPower2_last24hour.push({
            timestamp,
            value: isNaN(apparentpower2) || apparentpower2 === undefined ? 0 : apparentpower2,
          });

          apparentPower3_last24hour.push({
            timestamp,
            value: isNaN(apparentpower3) || apparentpower3 === undefined ? 0 : apparentpower3,
          });

          averagePowerFactor_last24hour.push({
            timestamp,
            value:
              isNaN(averagepf) || averagepf === undefined ? 0 : averagepf,
          });

          powerFactor1_last24hour.push({
            timestamp,
            value: isNaN(powerfactor1) || powerfactor1 === undefined ? 0 : powerfactor1,
          });

          powerFactor2_last24hour.push({
            timestamp,
            value: isNaN(powerfactor2) || powerfactor2 === undefined ? 0 : powerfactor2,
          });

          powerFactor3_last24hour.push({
            timestamp,
            value: isNaN(powerfactor3) || powerfactor3 === undefined ? 0 : powerfactor3,
          });

          frequency_of_last24hour.push({
            timestamp,
            value: isNaN(frequency) || frequency === undefined ? 0 : frequency,
          });

          max_active_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(mxactpower) || mxactpower === undefined ? 0 : mxactpower,
          });

          max_reactive_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(mxreactpower) || mxreactpower === undefined
                ? 0
                : mxreactpower,
          });

          max_apparend_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(mxapppopower) || mxapppopower === undefined
                ? 0
                : mxapppopower,
          });

          min_active_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(minactpower) || minactpower === undefined ? 0 : minactpower,
          });

          min_reactive_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(minreactpower) || minreactpower === undefined
                ? 0
                : minreactpower,
          });

          r_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(rvtgphthd) || rvtgphthd === undefined ? 0 : rvtgphthd,
          });

          y_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(yvtgphthd) || yvtgphthd === undefined ? 0 : yvtgphthd,
          });

          b_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(bvtgphthd) || bvtgphthd === undefined ? 0 : bvtgphthd,
          });

          ry_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value:
              isNaN(ryvtgphthd) || ryvtgphthd === undefined ? 0 : ryvtgphthd,
          });

          yb_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value:
              isNaN(ybvtgphthd) || ybvtgphthd === undefined ? 0 : ybvtgphthd,
          });

          rb_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value:
              isNaN(rbvtgphthd) || rbvtgphthd === undefined ? 0 : rbvtgphthd,
          });

          r_phase_THD_Current_of_current_month.push({
            timestamp,
            value: isNaN(rcrtphthd) || rcrtphthd === undefined ? 0 : rcrtphthd,
          });

          y_phase_THD_Current_of_current_month.push({
            timestamp,
            value: isNaN(ycrtphthd) || ycrtphthd === undefined ? 0 : ycrtphthd,
          });

          b_phase_THD_Current_of_current_month.push({
            timestamp,
            value: isNaN(bcrtphthd) || bcrtphthd === undefined ? 0 : bcrtphthd,
          });

          average_voltage_last24hour.push({
            timestamp,
            value:
              averagevoltage
          });

          intLNVoltPdfData.push({
            TimeStamp: timestamp,
            ...(customTagData?.["v1"]?.sensorStatus
              ? {
                [`${customTagData["v1"]?.sensorAlias} ${customTagData["v1"]?.sensorUnit}`]:
                  voltage1,
              }
              : !customTagData?.["v1"]
                ? { "Voltage 1": voltage1 }
                : {}),

            ...(customTagData?.["v2"]?.sensorStatus
              ? {
                [`${customTagData["v2"]?.sensorAlias} ${customTagData["v2"]?.sensorUnit}`]:
                  voltage2,
              }
              : !customTagData?.["v2"]
                ? { "Voltage 2": voltage2 }
                : {}),

            ...(customTagData?.["v3"]?.sensorStatus
              ? {
                [`${customTagData["v3"]?.sensorAlias} ${customTagData["v3"]?.sensorUnit}`]:
                  voltage3,
              }
              : !customTagData?.["v3"]
                ? { "Voltage 3": voltage3 }
                : {}),
          });


          intCurrPdfData.push({
            TimeStamp: timestamp,
            ...(customTagData?.["a_cl"]?.sensorStatus
              ? {
                [`${customTagData["a_cl"]?.sensorAlias} ${customTagData["a_cl"]?.sensorUnit}`]:
                  averagecurrent,
              }
              : !customTagData?.["a_cl"]
                ? { "Avarage Current": averagecurrent }
                : {}),

            ...(customTagData?.["cl1"]?.sensorStatus
              ? {
                [`${customTagData["cl1"]?.sensorAlias} ${customTagData["cl1"]?.sensorUnit}`]:
                  current1,
              }
              : !customTagData?.["cl1"]
                ? { "Current 1": current1 }
                : {}),

            ...(customTagData?.["cl2"]?.sensorStatus
              ? {
                [`${customTagData["cl2"]?.sensorAlias} ${customTagData["cl2"]?.sensorUnit}`]:
                  current2,
              }
              : !customTagData?.["cl2"]
                ? { "Current 2": current2 }
                : {}),

            ...(customTagData?.["cl3"]?.sensorStatus
              ? {
                [`${customTagData["cl3"]?.sensorAlias} ${customTagData["cl3"]?.sensorUnit}`]:
                  current3,
              }
              : !customTagData?.["cl3"]
                ? { "Current 3": current3 }
                : {}),
          });

          getaveragepfData.push({
            TimeStamp: timestamp,
            ...(customTagData?.["a_pf"]?.sensorStatus
              ? {
                [`${customTagData["a_pf"]?.sensorAlias} ${customTagData["a_pf"]?.sensorUnit}`]:
                  averagepf,
              }
              : !customTagData?.["a_pf"]
                ? { "Average PF": averagepf }
                : {}),

            ...(customTagData?.["pf1"]?.sensorStatus
              ? {
                [`${customTagData["pf1"]?.sensorAlias} ${customTagData["pf1"]?.sensorUnit}`]:
                  powerfactor1,
              }
              : !customTagData?.["pf1"]
                ? { "Power Factor 1": powerfactor1 }
                : {}),

            ...(customTagData?.["pf2"]?.sensorStatus
              ? {
                [`${customTagData["pf2"]?.sensorAlias} ${customTagData["pf2"]?.sensorUnit}`]:
                  powerfactor2,
              }
              : !customTagData?.["pf2"]
                ? { "Power Factor 2": powerfactor2 }
                : {}),

            ...(customTagData?.["pf3"]?.sensorStatus
              ? {
                [`${customTagData["pf3"]?.sensorAlias} ${customTagData["pf3"]?.sensorUnit}`]:
                  powerfactor3,
              }
              : !customTagData?.["pf3"]
                ? { "Power Factor 3": powerfactor3 }
                : {}),
          });

          if (valueInstTDH === 0) {
            getTDHDataToExport.push({
              TimeStamp: timestamp,
              "R Phase Voltage": rvtgphthd,
              "Y Phase Voltage": yvtgphthd,
              "B Phase Voltage": bvtgphthd,
            });
          } else if (valueInstTDH === 1) {
            getTDHDataToExport.push({
              TimeStamp: timestamp,
              "RY Phase Voltage": ryvtgphthd,
              "YB Phase Voltage": ybvtgphthd,
              "RB Phase Voltage": rbvtgphthd,
            });
          } else {
            getTDHDataToExport.push({
              TimeStamp: timestamp,
              "R Phase Current": rcrtphthd,
              "Y Phase Current": ycrtphthd,
              "B Phase Current": bcrtphthd,
            });
          }

          frequency_for_pdf.push({
            TimeStamp: timestamp,
            Frequency: frequency,
          });
        }
      );
    }
  }

  const linechartAnalysisData = [
    ...(customTagData?.["a_v"]?.sensorStatus === true && customTagData?.["a_v"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["a_v"]?.sensorAlias || "Average Voltage",
          color: "#FF0000", // Red color for Average Voltage
          data: average_voltage_last24hour,
          postfix: customTagData?.["a_v"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["a_cl"]?.sensorStatus === true && customTagData?.["a_cl"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["a_cl"]?.sensorAlias || "Average Current",
          color: "#FFFF00", // Yellow color for Average Current
          data: average_current_last24hour,
          postfix: customTagData?.["a_cl"]?.sensorUnit || " kVarh",
        },
      ]
      : []),

    ...(customTagData?.["a_pf"]?.sensorStatus === true && customTagData?.["a_pf"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["a_pf"]?.sensorAlias || "Average PF",
          color: "#0000FF", // Blue color for Average PF
          data: average_pf_last24hour,
          postfix: customTagData?.["a_pf"]?.sensorUnit || " kVah",
        },
      ]
      : []),

    ...(customTagData?.["t_kw"]?.sensorStatus === true && customTagData?.["t_kw"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["t_kw"]?.sensorAlias || "Total Active Power",
          color: "#228B22", // Green color for Total Active Power
          data: total_activepower_last24hour,
          postfix: customTagData?.["t_kw"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["t_kva"]?.sensorStatus === true && customTagData?.["t_kva"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["t_kva"]?.sensorAlias || "Total Apparent Power",
          color: "#8A2BE2", // Purple color for Total Apparent Power
          data: total_apparentpower_last24hour,
          postfix: customTagData?.["t_kva"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["t_kv"]?.sensorStatus === true && customTagData?.["t_kv"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["t_kv"]?.sensorAlias || "Total Reactive Power",
          color: "#FF6347", // Tomato color for Total Reactive Power
          data: total_reactivepower_last24hour,
          postfix: customTagData?.["t_kv"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["v1"]?.sensorStatus === true && customTagData?.["v1"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["v1"]?.sensorAlias || "Voltage 1",
          color: "#00BFFF", // DeepSkyBlue color for Voltage 1
          data: voltage1_last24hour,
          postfix: customTagData?.["v1"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["v2"]?.sensorStatus === true && customTagData?.["v2"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["v2"]?.sensorAlias || "Voltage 2",
          color: "#FFD700", // Gold color for Voltage 2
          data: voltage2_last24hour,
          postfix: customTagData?.["v2"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["v3"]?.sensorStatus === true && customTagData?.["v3"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["v3"]?.sensorAlias || "Voltage 3",
          color: "#32CD32", // LimeGreen color for Voltage 3
          data: voltage3_last24hour,
          postfix: customTagData?.["v3"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["cl1"]?.sensorStatus === true && customTagData?.["cl1"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["cl1"]?.sensorAlias || "Current 1",
          color: "#FF4500", // OrangeRed color for Current 1
          data: current1_last24hour,
          postfix: customTagData?.["cl1"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["cl2"]?.sensorStatus === true && customTagData?.["cl2"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["cl2"]?.sensorAlias || "Current 2",
          color: "#2E8B57", // SeaGreen color for Current 2
          data: current2_last24hour,
          postfix: customTagData?.["cl2"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["cl3"]?.sensorStatus === true && customTagData?.["cl3"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["cl3"]?.sensorAlias || "Current 3",
          color: "#8B4513", // SaddleBrown color for Current 3
          data: current3_last24hour,
          postfix: customTagData?.["cl3"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["pf1"]?.sensorStatus === true && customTagData?.["pf1"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["pf1"]?.sensorAlias || "PF 1",
          color: "#FF1493", // DeepPink color for PF 1
          data: powerFactor1_last24hour,
          postfix: customTagData?.["pf1"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["pf2"]?.sensorStatus === true && customTagData?.["pf2"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["pf2"]?.sensorAlias || "PF 2",
          color: "#A52A2A", // Brown color for PF 2
          data: powerFactor2_last24hour,
          postfix: customTagData?.["pf2"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["pf3"]?.sensorStatus === true && customTagData?.["pf3"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["pf3"]?.sensorAlias || "PF 3",
          color: "#DAA520", // GoldenRod color for PF 3
          data: powerFactor3_last24hour,
          postfix: customTagData?.["pf3"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["kw1"]?.sensorStatus === true && customTagData?.["kw1"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["kw1"]?.sensorAlias || "Active Power 1",
          color: "#8A2BE2", // BlueViolet color for Active Power 1
          data: activePower1_last24hour,
          postfix: customTagData?.["kw1"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["kw2"]?.sensorStatus === true && customTagData?.["kw2"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["kw2"]?.sensorAlias || "Active Power 2",
          color: "#FF6347", // Tomato color for Active Power 2
          data: activePower2_last24hour,
          postfix: customTagData?.["kw2"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["kw3"]?.sensorStatus === true && customTagData?.["kw3"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["kw3"]?.sensorAlias || "Active Power 3",
          color: "#98FB98", // PaleGreen color for Active Power 3
          data: activePower3_last24hour,
          postfix: customTagData?.["kw3"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["kv1"]?.sensorStatus === true && customTagData?.["kv1"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["kv1"]?.sensorAlias || "Reactive Power 1",
          color: "#00FFFF", // Cyan color for Reactive Power 1
          data: reactivePower1_last24hour,
          postfix: customTagData?.["kv1"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["kv2"]?.sensorStatus === true && customTagData?.["kv2"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["kv2"]?.sensorAlias || "Reactive Power 2",
          color: "#FF00FF", // Fuchsia color for Reactive Power 2
          data: reactivePower2_last24hour,
          postfix: customTagData?.["kv2"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["kv3"]?.sensorStatus === true && customTagData?.["kv3"]?.displayTagStatus === true
      ? [
        {
          name: customTagData?.["kv3"]?.sensorAlias || "Reactive Power 3",
          color: "#800080", // Purple color for Reactive Power 3
          data: reactivePower3_last24hour,
          postfix: customTagData?.["kv3"]?.sensorUnit || " kWh",
        },
      ]
      : []),
    
      ...(customTagData?.["freq"]?.sensorStatus === true && customTagData?.["freq"]?.displayTagStatus === true
        ? [
          {
            name: customTagData?.["freq"]?.sensorAlias || "Frequency",
            color: "#800084", 
            data: frequency_of_last24hour,
            postfix: customTagData?.["freq"]?.sensorUnit || " kWh",
          },
        ]
        : []),
  ];



  const lineChartOfVoltageVariations: any = [
    ...(customTagData?.["v1"]?.sensorStatus || !customTagData?.["v1"]
      ? [
        {
          name: customTagData?.["v1"]?.sensorAlias || "Voltage 1",
          color: "#008000",
          data: voltage1_last24hour,
          postfix: customTagData?.["v1"]?.sensorUnit || " V",
        },
      ]
      : []),

    ...(customTagData?.["v2"]?.sensorStatus || !customTagData?.["v2"]
      ? [
        {
          name: customTagData?.["v2"]?.sensorAlias || "Voltage 2",
          color: "#FF0000",
          data: voltage2_last24hour,
          postfix: customTagData?.["v2"]?.sensorUnit || " V",
        },
      ]
      : []),

    ...(customTagData?.["v3"]?.sensorStatus || !customTagData?.["v3"]
      ? [
        {
          name: customTagData?.["v3"]?.sensorAlias || "Voltage 3",
          color: "#FFFF00",
          data: voltage3_last24hour,
          postfix: customTagData?.["v3"]?.sensorUnit || " V",
        },
      ]
      : []),
  ];

  const lineChartOfLLVoltageVariations: any = [
    ...(customTagData?.["av_ll"]?.sensorStatus || !customTagData?.["av_ll"]
      ? [
        {
          name: customTagData?.["av_ll"]?.sensorAlias || "Average L-L",
          color: "#008000",
          data: avarage_L_L_data_of_current_month,
          postfix: customTagData?.["av_ll"]?.sensorUnit || " V",
        },
      ]
      : []),

    ...(customTagData?.["v12"]?.sensorStatus || !customTagData?.["v12"]
      ? [
        {
          name: customTagData?.["v12"]?.sensorAlias || "RY Phase Voltage",
          color: "#FF0000",
          data: ry_phase_Voltage_of_current_month,
          postfix: customTagData?.["v12"]?.sensorUnit || " V",
        },
      ]
      : []),

    ...(customTagData?.["v23"]?.sensorStatus || !customTagData?.["v23"]
      ? [
        {
          name: customTagData?.["v23"]?.sensorAlias || "YB Phase Voltage",
          color: "#FFFF00",
          data: yb_phase_Voltage_of_current_month,
          postfix: customTagData?.["v23"]?.sensorUnit || " V",
        },
      ]
      : []),

    ...(customTagData?.["v31"]?.sensorStatus || !customTagData?.["v31"]
      ? [
        {
          name: customTagData?.["v31"]?.sensorAlias || "RB Phase Voltage",
          color: "#0000FF",
          data: rb_phase_Voltage_of_current_month,
          postfix: customTagData?.["v31"]?.sensorUnit || " V",
        },
      ]
      : []),
  ];
  const lineChartOfCurrentVariations: any = [
    ...(customTagData?.["a_cl"]?.sensorStatus || !customTagData?.["a_cl"]
      ? [
        {
          name: customTagData?.["a_cl"]?.sensorAlias || "Average Current",
          color: "#008000",
          data: total_Current_last24hour,
          postfix: customTagData?.["a_cl"]?.sensorUnit || " V",
        },
      ]
      : []),
    ...(customTagData?.["cl1"]?.sensorStatus || !customTagData?.["cl1"]
      ? [
        {
          name: customTagData?.["cl1"]?.sensorAlias || "R Phase Current",
          color: "#FF0000",
          data: current1_last24hour,
          postfix: customTagData?.["cl1"]?.sensorUnit || " A",
        },
      ]
      : []),

    ...(customTagData?.["cl2"]?.sensorStatus || !customTagData?.["cl2"]
      ? [
        {
          name: customTagData?.["cl2"]?.sensorAlias || "Y Phase Current",
          color: "#FFFF00",
          data: current2_last24hour,
          postfix: customTagData?.["cl2"]?.sensorUnit || " A",
        },
      ]
      : []),

    ...(customTagData?.["cl3"]?.sensorStatus || !customTagData?.["cl3"]
      ? [
        {
          name: customTagData?.["cl3"]?.sensorAlias || "B Phase Current",
          color: "#0000FF",
          data: current3_last24hour,
          postfix: customTagData?.["cl3"]?.sensorUnit || " A",
        },
      ]
      : []),
  ];

  const lineChartOfActivePowerVariations: any = [
    ...(customTagData?.["t_kw"]?.sensorStatus || !customTagData?.["t_kw"]
      ? [
        {
          name: customTagData?.["t_kw"]?.sensorAlias || "Total Active Power",
          color: "#008000",
          data: total_Active_power_last24hour,
          postfix: customTagData?.["t_kw"]?.sensorUnit || " kW",
        },
      ]
      : []),

    ...(customTagData?.["kw1"]?.sensorStatus || !customTagData?.["kw1"]
      ? [
        {
          name: customTagData?.["kw1"]?.sensorAlias || "Active Power 1",
          color: "#FF0000",
          data: activePower1_last24hour,
          postfix: customTagData?.["kw1"]?.sensorUnit || " kW",
        },
      ]
      : []),

    ...(customTagData?.["kw2"]?.sensorStatus || !customTagData?.["kw2"]
      ? [
        {
          name: customTagData?.["kw2"]?.sensorAlias || "Active Power 2",
          color: "#FFFF00",
          data: activePower2_last24hour,
          postfix: customTagData?.["kw2"]?.sensorUnit || " kW",
        },
      ]
      : []),

    ...(customTagData?.["kw3"]?.sensorStatus || !customTagData?.["kw3"]
      ? [
        {
          name: customTagData?.["kw3"]?.sensorAlias || "Active Power 3",
          color: "#0000FF",
          data: activePower3_last24hour,
          postfix: customTagData?.["kw3"]?.sensorUnit || " kW",
        },
      ]
      : []),
  ];
  const lineChartOfReactivePowerVariations: any = [
    ...(customTagData?.["t_kv"]?.sensorStatus || !customTagData?.["t_kv"]
      ? [
        {
          name:
            customTagData?.["t_kv"]?.sensorAlias || "Total Reactive Power",
          color: "#008000",
          data: total_ReactivePower_last24hour,
          postfix: customTagData?.["t_kv"]?.sensorUnit || " kVAr",
        },
      ]
      : []),
    ...(customTagData?.["kv1"]?.sensorStatus || !customTagData?.["kv1"]
      ? [
        {
          name:
            customTagData?.["kv1"]?.sensorAlias || "Reactive Power 1",
          color: "#FF0000",
          data: reactivePower1_last24hour,
          postfix: customTagData?.["kv1"]?.sensorUnit || " kVAr",
        },
      ]
      : []),
    ...(customTagData?.["kv2"]?.sensorStatus || !customTagData?.["kv2"]
      ? [
        {
          name:
            customTagData?.["kv2"]?.sensorAlias || "Reactive Power 2",
          color: "#FFFF00",
          data: reactivePower2_last24hour,
          postfix: customTagData?.["kv2"]?.sensorUnit || " kVAr",
        },
      ]
      : []),
    ...(customTagData?.["kv3"]?.sensorStatus || !customTagData?.["kv3"]
      ? [
        {
          name:
            customTagData?.["kv3"]?.sensorAlias || "Reactive Power 3",
          color: "#0000FF",
          data: reactivePower3_last24hour,
          postfix: customTagData?.["kv3"]?.sensorUnit || " kVAr",
        },
      ]
      : []),
  ];
  const lineChartOfApparentPowerVariations: any = [
    ...(customTagData?.["t_kva"]?.sensorStatus || !customTagData?.["t_kva"]
      ? [
        {
          name:
            customTagData?.["t_kva"]?.sensorAlias || "Total Apparent Power",
          color: "#008000",
          data: total_Apparent_Power_last24hour,
          postfix: customTagData?.["t_kva"]?.sensorUnit || " kVa",
        },
      ]
      : []),
    ...(customTagData?.["kva1"]?.sensorStatus || !customTagData?.["kva1"]
      ? [
        {
          name:
            customTagData?.["kva1"]?.sensorAlias || "Apparent Power 1",
          color: "#FF0000",
          data: apparentPower1_last24hour,
          postfix: customTagData?.["kva1"]?.sensorUnit || " kVa",
        },
      ]
      : []),
    ...(customTagData?.["kva2"]?.sensorStatus || !customTagData?.["kva2"]
      ? [
        {
          name:
            customTagData?.["kva2"]?.sensorAlias || "Apparent Power 2",
          color: "#FFFF00",
          data: apparentPower2_last24hour,
          postfix: customTagData?.["kva2"]?.sensorUnit || " kVa",
        },
      ]
      : []),
    ...(customTagData?.["kva3"]?.sensorStatus || !customTagData?.["kva3"]
      ? [
        {
          name:
            customTagData?.["kva3"]?.sensorAlias || "Apparent Power 3",
          color: "#0000FF",
          data: apparentPower3_last24hour,
          postfix: customTagData?.["kva3"]?.sensorUnit || " kVa",
        },
      ]
      : []),
  ];
  const lineChartOfaveragepfVariations: any = [
    ...(customTagData?.["a_pf"]?.sensorStatus || !customTagData?.["a_pf"]
      ? [
        {
          name: customTagData?.["a_pf"]?.sensorAlias || "Average PF",
          color: "#008000",
          data: averagePowerFactor_last24hour,
        },
      ]
      : []),

    ...(customTagData?.["pf1"]?.sensorStatus || !customTagData?.["pf1"]
      ? [
        {
          name: customTagData?.["pf1"]?.sensorAlias || "PF 1",
          color: "#FF0000",
          data: powerFactor1_last24hour,
        },
      ]
      : []),
    ...(customTagData?.["pf2"]?.sensorStatus || !customTagData?.["pf2"]
      ? [
        {
          name: customTagData?.["pf2"]?.sensorAlias || "PF 2",
          color: "#FFFF00",
          data: powerFactor2_last24hour,
        },
      ]
      : []),
    ...(customTagData?.["pf3"]?.sensorStatus || !customTagData?.["pf3"]
      ? [
        {
          name: customTagData?.["pf3"]?.sensorAlias || "PF 3",
          color: "#0000FF",
          data: powerFactor3_last24hour,
        },
      ]
      : []),
  ];

  const lineChartOfFrequencyVariations: any = [
    ...(customTagData?.["freq"]?.sensorStatus || !customTagData?.["freq"]
      ? [
        {
          name: customTagData?.["freq"]?.sensorAlias || "Frequency",
          color: "#008000",
          data: frequency_of_last24hour,
          postfix: customTagData?.["freq"]?.sensorUnit || " Hz",
        },
      ]
      : []),
  ];
  //Frequency Data Ends

  //Demand Line chart starts here
  const lineChartOfMaxDemandDataVariations: any = [
    ...(customTagData?.["ap_maxd"]?.sensorStatus || !customTagData?.["ap_maxd"]
      ? [
        {
          name: customTagData?.["ap_maxd"]?.sensorAlias || "Max Active Power",
          color: "#008000",
          data: max_active_demand_of_current_month,
          postfix: customTagData?.["ap_maxd"]?.sensorUnit || " kW",
        },
      ]
      : []),
    ...(customTagData?.["rp_maxd"]?.sensorStatus || !customTagData?.["rp_maxd"]
      ? [
        {
          name:
            customTagData?.["rp_maxd"]?.sensorAlias || "Max Reactive Power",
          color: "#FF0000",
          data: max_reactive_demand_of_current_month,
          postfix: customTagData?.["rp_maxd"]?.sensorUnit || " kVar",
        },
      ]
      : []),
    ...(customTagData?.["apr_maxd"]?.sensorStatus ||
      !customTagData?.["apr_maxd"]
      ? [
        {
          name:
            customTagData?.["apr_maxd"]?.sensorAlias || "Max Apparent Power",
          color: "#FFFF00",
          data: max_apparend_demand_of_current_month,
          postfix: customTagData?.["apr_maxd"]?.sensorUnit || " kVa",
        },
      ]
      : []),
  ];

  const lineChartOfMinDemandDataVariations: any = [
    ...(customTagData?.["ap_mind"]?.sensorStatus || !customTagData?.["ap_mind"]
      ? [
        {
          name: customTagData?.["ap_mind"]?.sensorAlias || "Min Active Power",
          color: "#008000",
          data: min_active_demand_of_current_month,
          postfix: customTagData?.["ap_mind"]?.sensorUnit || " kW",
        },
      ]
      : []),
    ...(customTagData?.["rp_mind"]?.sensorStatus || !customTagData?.["rp_mind"]
      ? [
        {
          name:
            customTagData?.["rp_mind"]?.sensorAlias || "Min Reactive Power",
          color: "#FF0000",
          data: min_reactive_demand_of_current_month,
          postfix: customTagData?.["rp_mind"]?.sensorUnit || " kVar",
        },
      ]
      : []),
  ];

  const lineChartOfTHDVoltageVariations: any = [
    ...(customTagData?.["thd_v1n"]?.sensorStatus || !customTagData?.["thd_v1n"]
      ? [
        {
          name: customTagData?.["thd_v1n"]?.sensorAlias || "R Phase Voltage",
          color: "#008000",
          data: r_phase_THD_Voltage_of_current_month,
          postfix: customTagData?.["thd_v1n"]?.sensorUnit || " %",
        },
      ]
      : []),
    ...(customTagData?.["thd_v2n"]?.sensorStatus || !customTagData?.["thd_v2n"]
      ? [
        {
          name: customTagData?.["thd_v2n"]?.sensorAlias || "Y Phase Voltage",
          color: "#FF0000",
          data: y_phase_THD_Voltage_of_current_month,
          postfix: customTagData?.["thd_v2n"]?.sensorUnit || " %",
        },
      ]
      : []),
    ...(customTagData?.["thd_v3n"]?.sensorStatus || !customTagData?.["thd_v3n"]
      ? [
        {
          name: customTagData?.["thd_v3n"]?.sensorAlias || "B Phase Voltage",
          color: "#FFFF00",
          data: b_phase_THD_Voltage_of_current_month,
          postfix: customTagData?.["thd_v3n"]?.sensorUnit || " %",
        },
      ]
      : []),
  ];

  const lineChartOfTHDLineToLineVoltVariations: any = [
    ...(customTagData?.["thd_v12"]?.sensorStatus || !customTagData?.["thd_v12"]
      ? [
        {
          name: customTagData?.["thd_v12"]?.sensorAlias || "RY Phase Voltage",
          color: "#008000",
          data: ry_phase_THD_Voltage_of_current_month,
          postfix: customTagData?.["thd_v12"]?.sensorUnit || " %",
        },
      ]
      : []),
    ...(customTagData?.["thd_v23"]?.sensorStatus || !customTagData?.["thd_v23"]
      ? [
        {
          name: customTagData?.["thd_v23"]?.sensorAlias || "YB Phase Voltage",
          color: "#FF0000",
          data: yb_phase_THD_Voltage_of_current_month,
          postfix: customTagData?.["thd_v23"]?.sensorUnit || " %",
        },
      ]
      : []),
    ...(customTagData?.["thd_v31"]?.sensorStatus || !customTagData?.["thd_v31"]
      ? [
        {
          name: customTagData?.["thd_v31"]?.sensorAlias || "RB Phase Voltage",
          color: "#FFFF00",
          data: rb_phase_THD_Voltage_of_current_month,
          postfix: customTagData?.["thd_v31"]?.sensorUnit || " %",
        },
      ]
      : []),
  ];

  const lineChartOfTHDCurrentVariations: any = [
    ...(customTagData?.["thd_ci1"]?.sensorStatus || !customTagData?.["thd_ci1"]
      ? [
        {
          name: customTagData?.["thd_ci1"]?.sensorAlias || "R Phase Current",
          color: "#008000",
          data: r_phase_THD_Current_of_current_month,
          postfix: customTagData?.["thd_ci1"]?.sensorUnit || " %",
        },
      ]
      : []),
    ...(customTagData?.["thd_ci2"]?.sensorStatus || !customTagData?.["thd_ci2"]
      ? [
        {
          name: customTagData?.["thd_ci2"]?.sensorAlias || "Y Phase Current",
          color: "#FF0000",
          data: y_phase_THD_Current_of_current_month,
          postfix: customTagData?.["thd_ci2"]?.sensorUnit || " %",
        },
      ]
      : []),
    ...(customTagData?.["thd_ci3"]?.sensorStatus || !customTagData?.["thd_ci3"]
      ? [
        {
          name: customTagData?.["thd_ci3"]?.sensorAlias || "B Phase Current",
          color: "#FFFF00",
          data: b_phase_THD_Current_of_current_month,
          postfix: customTagData?.["thd_ci3"]?.sensorUnit || " %",
        },
      ]
      : []),
  ];
  //THD Data Ends

  const getPowerDataToExport = (valueInstPower: number) => {
    if (valueInstPower === 0) {
      return apiData.map(
        ({ timestamp, totalactivepower, activepower1, activepower2, activepower3 }: any) => ({
          TimeStamp: timestamp,

          ...(customTagData?.["t_kw"]?.sensorStatus
            ? {
              [`${customTagData["t_kw"]?.sensorAlias} ${customTagData["t_kw"]?.sensorUnit || "kW"
                }`]: totalactivepower,
            }
            : !customTagData?.["t_kw"]
              ? { "Total Active (kW)": totalactivepower }
              : {}),

          ...(customTagData?.["kw1"]?.sensorStatus
            ? {
              [`${customTagData["kw1"]?.sensorAlias} ${customTagData["kw1"]?.sensorUnit || "kW"
                }`]: activepower1,
            }
            : !customTagData?.["kw1"]
              ? { "R Phase Active (kW)": activepower1 }
              : {}),

          ...(customTagData?.["kw2"]?.sensorStatus
            ? {
              [`${customTagData["kw2"]?.sensorAlias} ${customTagData["kw2"]?.sensorUnit || "kW"
                }`]: activepower2,
            }
            : !customTagData?.["kw2"]
              ? { "Y Phase Active (kW)": activepower2 }
              : {}),

          ...(customTagData?.["kw3"]?.sensorStatus
            ? {
              [`${customTagData["kw3"]?.sensorAlias} ${customTagData["kw3"]?.sensorUnit || "kW"
                }`]: activepower3,
            }
            : !customTagData?.["kw3"]
              ? { "B Phase Active (kW)": activepower3 }
              : {}),
        })
      );
    } else if (valueInstPower === 1) {
      return apiData.map(
        ({
          timestamp,
          totalreactivepower,
          reactivepower1,
          reactivepower2,
          reactivepower3,
        }: any) => ({
          TimeStamp: timestamp,

          ...(customTagData?.["t_kv"]?.sensorStatus
            ? {
              [`${customTagData["t_kv"]?.sensorAlias} ${customTagData["t_kv"]?.sensorUnit || "kVAr"
                }`]: totalreactivepower,
            }
            : !customTagData?.["t_kv"]
              ? { "Total Reactive (kVAr)": totalreactivepower }
              : {}),

          ...(customTagData?.["kv1"]?.sensorStatus
            ? {
              [`${customTagData["kv1"]?.sensorAlias} ${customTagData["kv1"]?.sensorUnit || "kVAr"
                }`]: reactivepower1,
            }
            : !customTagData?.["kv1"]
              ? { "R Phase Reactive (kVAr)": reactivepower1 }
              : []),

          ...(customTagData?.["kv2"]?.sensorStatus
            ? {
              [`${customTagData["kv2"]?.sensorAlias} ${customTagData["kv2"]?.sensorUnit || "kVAr"
                }`]: reactivepower2,
            }
            : !customTagData?.["kv2"]
              ? { "Y Phase Reactive (kVAr)": reactivepower2 }
              : []),

          ...(customTagData?.["kv3"]?.sensorStatus
            ? {
              [`${customTagData["kv3"]?.sensorAlias} ${customTagData["kv3"]?.sensorUnit || "kVAr"
                }`]: reactivepower3,
            }
            : !customTagData?.["kv3"]
              ? { "B Phase Reactive (kVAr)": reactivepower3 }
              : []),
        })
      );
    } else if (valueInstPower === 2) {
      return apiData.map(
        ({ timestamp, totalapparentpower, apparentpower1, apparentpower2, apparentpower3 }: any) => ({
          TimeStamp: timestamp,

          ...(customTagData?.["t_kva"]?.sensorStatus
            ? {
              [`${customTagData["t_kva"]?.sensorAlias} ${customTagData["t_kva"]?.sensorUnit || "kVa"
                }`]: totalapparentpower,
            }
            : !customTagData?.["t_kva"]
              ? { "Total Apparent (kVa)": totalapparentpower }
              : []),

          ...(customTagData?.["kva1"]?.sensorStatus
            ? {
              [`${customTagData["kva1"]?.sensorAlias} ${customTagData["kva1"]?.sensorUnit || "kVa"
                }`]: apparentpower1,
            }
            : !customTagData?.["kva1"]
              ? { "R Phase Apparent (kVa)": apparentpower1 }
              : []),

          ...(customTagData?.["kva2"]?.sensorStatus
            ? {
              [`${customTagData["kva2"]?.sensorAlias} ${customTagData["kva2"]?.sensorUnit || "kVa"
                }`]: apparentpower2,
            }
            : !customTagData?.["kva2"]
              ? { "Y Phase Apparent (kVa)": apparentpower2 }
              : []),

          ...(customTagData?.["kva3"]?.sensorStatus
            ? {
              [`${customTagData["kva3"]?.sensorAlias} ${customTagData["kva3"]?.sensorUnit || "kVa"
                }`]: apparentpower3,
            }
            : !customTagData?.["kva3"]
              ? { "B Phase Apparent (kVa)": apparentpower3 }
              : []),
        })
      );
    }
  };

  const getDemandDataToExport = (valueInstDemand: number) => {
    if (valueInstDemand === 0) {
      return apiData.map(
        ({ timestamp, mxactpower, mxreactpower, mxapppopower }: any) => ({
          TimeStamp: timestamp,

          ...(customTagData?.["ap_maxd"]?.sensorStatus
            ? {
              [`${customTagData["ap_maxd"]?.sensorAlias} ${customTagData["ap_maxd"]?.sensorUnit || "kW"
                }`]: mxactpower,
            }
            : !customTagData?.["ap_maxd"]
              ? { "Max Active Power (kW)": mxactpower }
              : []),

          ...(customTagData?.["rp_maxd"]?.sensorStatus
            ? {
              [`${customTagData["rp_maxd"]?.sensorAlias} ${customTagData["rp_maxd"]?.sensorUnit || "kVar"
                }`]: mxreactpower,
            }
            : !customTagData?.["rp_maxd"]
              ? { "Max Reactive Power (kVar)": mxreactpower }
              : []),

          ...(customTagData?.["apr_maxd"]?.sensorStatus
            ? {
              [`${customTagData["apr_maxd"]?.sensorAlias} ${customTagData["apr_maxd"]?.sensorUnit || "kVa"
                }`]: mxapppopower,
            }
            : !customTagData?.["apr_maxd"]
              ? { "Max Apparent Power (kVa)": mxapppopower }
              : []),
        })
      );
    } else if (valueInstDemand === 1) {
      return apiData.map(({ timestamp, minactpower, minreactpower }: any) => ({
        TimeStamp: timestamp,

        ...(customTagData?.["ap_mind"]?.sensorStatus
          ? {
            [`${customTagData["ap_mind"]?.sensorAlias} ${customTagData["ap_mind"]?.sensorUnit || "kW"
              }`]: minactpower,
          }
          : !customTagData?.["ap_mind"]
            ? { "Min Active Power (kW)": minactpower }
            : []),

        ...(customTagData?.["rp_mind"]?.sensorStatus
          ? {
            [`${customTagData["rp_mind"]?.sensorAlias} ${customTagData["rp_mind"]?.sensorUnit || "kVar"
              }`]: minreactpower,
          }
          : !customTagData?.["rp_mind"]
            ? { "Min Reactive Power (kVar)": minreactpower }
            : []),
      }));
    }
  };

  const handleExportBarChart = async (
    exportOption: string,
    dataValue: any,
    chart: any,
    label?: string
  ) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(dataValue);
        break;
      case "Download as PDF":
        if (dataValue && chart) {
          reportDataWithGraph(
            chart,
            dataValue,
            devices?.deviceName,
            devices?.Customer?.name,
            "Energy Summary",
            label ? label : "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return;
    }
  };

  let emptyBar: any[] | undefined;
  let consumtionData: any[] | undefined;
  if (valueAnalysis === 0) {
    const filterLast24hourDataOfEachHOur: any =
      filterDataFromEachHourEnergy(apiData);
    // console.log("filterLast24hourDataOfEach", filterLast24hourDataOfEachHOur);

    if (filterLast24hourDataOfEachHOur.length > 0) {
      const hourData = processHourlyDataEnergy(filterLast24hourDataOfEachHOur);
      // console.log("hour Data", hourData);

      consumtionData = processConsumptionDataEnergy(hourData);

      emptyBar = consumtionData ? fillMissingHours(consumtionData) : [];
    }
  } else if (valueAnalysis === 1) {
    let t: any = filterDataFromEachDayEnergy(apiData);
    if (t.length > 0) {
      const dailyData = processHourlyDataEnergyDaily(t);
      consumtionData = processMonthlyConsumptionEnergy(dailyData);

      emptyBar = consumtionData ? fillMissingDays(consumtionData) : [];
    }
  }

  const bargraph: any = [
    ...(customTagData?.["a_v"]?.sensorStatus === true ||
      !customTagData?.["a_v"]
      ? [
        {
          name: customTagData?.["a_v"]?.sensorAlias || "Average Voltage",
          color: "#101828",
          values: emptyBar
            ? emptyBar.map((item: any) => item.activeconsumption)
            : [],
          postfix: customTagData?.["a_v"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["a_cl"]?.sensorStatus === true ||
      !customTagData?.["a_cl"]
      ? [
        {
          name: customTagData?.["a_cl"]?.sensorAlias || "Average Current",
          color: "#0777A7",
          values: emptyBar
            ? emptyBar.map((item: any) => item.reactiveconsumption)
            : [],
          postfix: customTagData?.["a_cl"]?.sensorUnit || " kVarh",
        },
      ]
      : []),

    ...(customTagData?.["a_pf"]?.sensorStatus === true ||
      !customTagData?.["a_pf"]
      ? [
        {
          name: customTagData?.["a_pf"]?.sensorAlias || "Average PF",
          color: "#5EAFCF",
          values: emptyBar
            ? emptyBar.map((item: any) => item.apparentconsumption)
            : [],
          postfix: customTagData?.["a_pf"]?.sensorUnit || " kVAh",
        },
      ]
      : []),

    ...(customTagData?.["t_kw"]?.sensorStatus === true ||
      !customTagData?.["t_kw"]
      ? [
        {
          name: customTagData?.["t_kw"]?.sensorAlias || "Total Active Power",
          color: "#59E659",
          values: emptyBar
            ? emptyBar.map((item: any) => item.importactiveconsumption)
            : [],
          postfix: customTagData?.["t_kw"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["exp_ae"]?.sensorStatus === true ||
      !customTagData?.["exp_ae"]
      ? [
        {
          name: customTagData?.["exp_ae"]?.sensorAlias || "Export Active",
          color: "#19A519",
          values: emptyBar
            ? emptyBar.map((item: any) => item.exportactiveconsumption)
            : [],
          postfix: customTagData?.["exp_ae"]?.sensorUnit || " kWh",
        },
      ]
      : []),

    ...(customTagData?.["imp_re"]?.sensorStatus === true ||
      !customTagData?.["imp_re"]
      ? [
        {
          name: customTagData?.["imp_re"]?.sensorAlias || "Import Reactive",
          color: "#FF7074",
          values: emptyBar
            ? emptyBar.map((item: any) => item.importreactiveconsumption)
            : [],
          postfix: customTagData?.["imp_re"]?.sensorUnit || " kVarh",
        },
      ]
      : []),

    ...(customTagData?.["exp_re"]?.sensorStatus === true ||
      !customTagData?.["exp_re"]
      ? [
        {
          name: customTagData?.["exp_re"]?.sensorAlias || "Export Reactive",
          color: "#AA2F33",
          values: emptyBar
            ? emptyBar.map((item: any) => item.exportreactiveconsumption)
            : [],
          postfix: customTagData?.["exp_re"]?.sensorUnit || " kVarh",
        },
      ]
      : []),
  ];

  const xAxisCategories = emptyBar
    ? emptyBar.map((item) =>
      valueAnalysis === 0 ? item.hour + ":00" : item.day
    )
    : [];

  const handleTabClick = useCallback((index: number) => {
    setvalueAnalysis(+index);
    if (index === 1) {
      setLoader(true);
    }
  }, []);

  console.log("custom", apparentPower2_last24hour);


  return (
    <div>
      <div className="p-2 mb-4">
        <section style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", alignItems: "start" }}>
              <MobileTowerIcon
                levels={dashboardSts?.rssi || 0}
                time={dashboardSts?.timestamp}
              />
              <p className="ml-2">
                {"Last Data Received at " +
                  (dashboardSts?.timestamp || "YYYY-MM-DD HH:MM:SS")}
              </p>
            </div>
          </div>

          <div className="w-[280px] ">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
              <li className="w-full focus-within:z-10">
                <button
                  className={`inline-block w-full border border-gray-300 px-2 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                  aria-current="page"
                  style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(0)}
                >
                  Analysis
                </button>
              </li>

              <li className="w-full focus-within:z-10">
                <button
                  className={`inline-block w-full border border-gray-300 px-2 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                  style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(1)}
                >
                  Instantaneous
                </button>
              </li>
            </ul>
          </div>
        </section>
      </div>
      {value === 0 && (
        <div className="border border-gray-400 p-2 mb-4 mt-5">
          <div className="flex flex-col justify-between mt-5">
            <div className="border rounded-md border-gray-400 h-1/2 p-4">
              <div className="grid grid-cols-4 gap-5 my-cardTwo">
                {/* Average Voltage */}
                {customTagData?.["a_v"]?.sensorStatus && customTagData?.["a_v"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["a_v"]?.sensorAlias ||
                      "Average Voltage"
                    }
                    amount={`${dashboardSts.averagevoltage} ${customTagData?.["a_v"]?.sensorUnit || "kWh"}`}
                  />
                ) : !customTagData?.["a_v"] ? (
                  <CardThree
                    title="Average Voltage"
                    amount={ `${dashboardSts.averagevoltage} kWh` || "N/A kWh"}
                  />
                ) : null}

                {/* Average Current Card */}
                {customTagData?.["a_cl"]?.sensorStatus && customTagData?.["a_cl"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["a_cl"]?.sensorAlias ||
                      "Average Current"
                    }
                    amount={`${dashboardSts.averagecurrent} ${customTagData?.["a_cl"]?.sensorUnit || "kVarh"}`}
                  />
                ) : !customTagData?.["a_cl"] ? (
                  <CardThree
                    title="Average Current"
                    amount={ `${dashboardSts.averagecurrent} kVarh` || "N/A kVarh"}
                  />
                ) : null}

                {/* Average PF Card */}
                {customTagData?.["a_pf"]?.sensorStatus && customTagData?.["a_pf"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["a_pf"]?.sensorAlias ||
                      "Average PF"
                    }
                    amount={`${dashboardSts.averagepf} ${customTagData?.["a_pf"]?.sensorUnit || "N/A kVah"}` }
                  />
                ) : !customTagData?.["a_pf"] ? (
                  <CardThree
                    title="Average PF"
                    amount={`${dashboardSts.averagepf} kVah` || "N/A kVah"}
                  />
                ) : null}

                {/* Total Active Power Card */}
                {customTagData?.["t_kw"]?.sensorStatus && customTagData?.["t_kw"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["t_kw"]?.sensorAlias ||
                      "Total Active Power"
                    }
                    amount={`${dashboardSts.totalactivepower} ${customTagData?.["t_kw"]?.sensorUnit|| "N/A kWh"}`}
                  />
                ) : !customTagData?.["t_kw"] ? (
                  <CardThree
                    title="Total Active Power"
                    amount={`${dashboardSts.totalactivepower} kWh` || "N/A kWh"}
                  />
                ) : null}

                {/* Total Apparend Power */}
                {customTagData?.["t_kva"]?.sensorStatus && customTagData?.["t_kva"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["t_kva"]?.sensorAlias ||
                      "Total Apparend Power"
                    }
                    amount={`${dashboardSts.totalapparentpower} ${customTagData?.["t_kva"]?.sensorUnit|| "N/A kWh"}`}
                  />
                ) : !customTagData?.["t_kva"] ? (
                  <CardThree
                    title="Total Apparend Power"
                    amount={ `${dashboardSts.totalapparentpower} kWh` || "N/A kWh"}
                  />
                ) : null}

                {/* Total Reactive Power */}
                {customTagData?.["t_kv"]?.sensorStatus && customTagData?.["t_kv"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["t_kv"]?.sensorAlias ||
                      "Total Reactive Power"
                    }
                    amount={`${dashboardSts.totalreactivepower} ${customTagData?.["t_kv"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : !customTagData?.["t_kv"] ? (
                  <CardThree
                    title="Total Reactive Power"
                    amount={ `${dashboardSts.totalreactivepower} kWh` || "N/A kWh"}
                  />
                ) : null}

                {customTagData?.["v1"]?.sensorStatus && customTagData?.["v1"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["v1"]?.sensorAlias ||
                      "Voltage 1"
                    }
                    amount={ `${dashboardSts.voltage1} ${customTagData?.["v1"]?.sensorUnit || "N/A kWh" }`}
                  />
                ) : null}

                {customTagData?.["v2"]?.sensorStatus && customTagData?.["v2"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["v2"]?.sensorAlias ||
                      "Voltage 2"
                    }
                    amount={`${dashboardSts.voltage2} ${customTagData?.["v2"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["v3"]?.sensorStatus && customTagData?.["v3"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["v3"]?.sensorAlias ||
                      "Voltage 3"
                    }
                    amount={`${dashboardSts.voltage3} ${customTagData?.["v3"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["cl1"]?.sensorStatus && customTagData?.["cl1"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["cl1"]?.sensorAlias ||
                      "Current 1"
                    }
                    amount={ `${dashboardSts.current1} ${customTagData?.["cl1"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["cl2"]?.sensorStatus && customTagData?.["cl2"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["cl2"]?.sensorAlias ||
                      "Current 2"
                    }
                    amount={ `${dashboardSts.current2} ${customTagData?.["cl2"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["cl3"]?.sensorStatus && customTagData?.["cl3"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["cl3"]?.sensorAlias ||
                      "Current 3"
                    }
                    amount={`${dashboardSts.current3} ${customTagData?.["cl3"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["pf1"]?.sensorStatus && customTagData?.["pf1"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["pf1"]?.sensorAlias ||
                      "PF 1"
                    }
                    amount={ `${dashboardSts.powerfactor1} ${customTagData?.["pf1"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["pf2"]?.sensorStatus && customTagData?.["pf2"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["pf2"]?.sensorAlias ||
                      "PF 2"
                    }
                    amount={ `${dashboardSts.powerfactor2} ${customTagData?.["pf2"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["pf3"]?.sensorStatus && customTagData?.["pf3"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["pf3"]?.sensorAlias ||
                      "PF 3"
                    }
                    amount={ `${dashboardSts.powerfactor3} ${customTagData?.["pf3"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kw1"]?.sensorStatus && customTagData?.["kw1"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kw1"]?.sensorAlias ||
                      "Active Power 1"
                    }
                    amount={ `${dashboardSts.activepower1} ${customTagData?.["kw1"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kw2"]?.sensorStatus && customTagData?.["kw2"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kw2"]?.sensorAlias ||
                      "Active Power 2"
                    }
                    amount={ `${dashboardSts.activepower2} ${customTagData?.["kw2"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kw3"]?.sensorStatus && customTagData?.["kw3"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kw2"]?.sensorAlias ||
                      "Active Power 3"
                    }
                    amount={ `${dashboardSts.activepower3} ${customTagData?.["kw3"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kv1"]?.sensorStatus && customTagData?.["kv1"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kv1"]?.sensorAlias ||
                      "Reactive Power 1"
                    }
                    amount={`${dashboardSts.reactivepower1} ${customTagData?.["kv1"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kv2"]?.sensorStatus && customTagData?.["kv2"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kv2"]?.sensorAlias ||
                      "Reactive Power 2"
                    }
                    amount={`${dashboardSts.reactivepower2} ${customTagData?.["kv2"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kv3"]?.sensorStatus && customTagData?.["kv3"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kv3"]?.sensorAlias ||
                      "Reactive Power 3"
                    }
                    amount={ `${dashboardSts.reactivepower3} ${customTagData?.["kv3"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kva1"]?.sensorStatus && customTagData?.["kva1"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kva1"]?.sensorAlias ||
                      "Apparent Power 1"
                    }
                    amount={` ${dashboardSts.apparentpower1} ${customTagData?.["kva1"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kva2"]?.sensorStatus && customTagData?.["kva2"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kva2"]?.sensorAlias ||
                      "Apparent Power 2"
                    }
                    amount={` ${dashboardSts.apparentpower2} ${customTagData?.["kva2"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["kva3"]?.sensorStatus && customTagData?.["kva3"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["kva3"]?.sensorAlias ||
                      "Apparent Power 3"
                    }
                    amount={` ${dashboardSts.apparentpower3} ${customTagData?.["kva3"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}

                {customTagData?.["freq"]?.sensorStatus && customTagData?.["freq"]?.displayTagStatus ? (
                  <CardThree
                    title={
                      customTagData?.["freq"]?.sensorAlias ||
                      "Frequency"
                    }
                    amount={` ${dashboardSts.frequency} ${customTagData?.["freq"]?.sensorUnit || "N/A kWh"}`}
                  />
                ) : null}
              </div>
            </div>
            <div className="h-4"></div>

            <div className="border rounded-md border-gray-400 h-1/2 p-2">
              <div className="flex justify-center p-2">
                <div
                  className="my_temp_humidity"
                  style={{
                    padding: "10px",
                    width: "100%",
                  }}
                >
                  <div className="relative">
                    <p>
                      Energy meter reading data variation with time(between{" "}
                      {apiData.length > 0
                        ? apiData[0].timestamp +
                        " to " +
                        apiData[apiData.length - 1].timestamp
                        : " "}
                      )
                    </p>
                    <div className="absolute top-0 right-0">
                      <LongMenu
                        options={["Download as CSV"]}
                        onOptionClick={(exportOption) =>
                          handleExportBarChart(
                            exportOption,
                            energy_data_pdfData,
                            chartRef
                          )
                        }
                        id={1}
                      />
                    </div>
                    <div>
                      <div
                        ref={chartRef || ""}
                        style={{ marginTop: "20px" }}
                      >
                        <LineChart
                          data={linechartAnalysisData}
                          yAxisName="Data"
                          xAxisName="Time"
                          stroke={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {value === 1 && (
        <div>
          <div className="border border-gray-400 p-2 mb-4 mt-5">
            <div className="w-[800px] mb-5">
              <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex dark:divide-gray-700 dark:text-gray-400">
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 0 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(0)}
                  >
                    Voltage
                  </button>
                </li>

                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                    style={
                      valueInst === 1 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(1)}
                  >
                    Current
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900   transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 2 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(2)}
                  >
                    Power
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 3 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(3)}
                  >
                    Power Factor
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 4 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(4)}
                  >
                    Frequency
                  </button>
                </li>
              </ul>
            </div>

            {value === 1 && valueInst === 0 && (
              <>
                  <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Average Neutral Card */}
                    {customTagData?.["a_v"]?.sensorStatus ? (
                  <CardThree
                    title={
                      customTagData?.["a_v"]?.sensorAlias ||
                      "Average Voltage"
                    }
                    amount={ ` ${dashboardSts.averagevoltage} ${customTagData?.["a_v"].sensorUnit || "N/A kWh"}`}
                  />
                ) : !customTagData?.["a_v"] ? (
                  <CardThree
                    title="Average Voltage"
                    amount={ `${dashboardSts.averagevoltage} kWh`|| "N/A kWh"}
                  />
                ) : null}

                    {customTagData?.["v1"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["v1"]?.sensorAlias ||
                          "Voltage 1"
                        }
                        amount={`${dashboardSts.voltage1
                          ? dashboardSts.voltage1
                          : "0"
                          } ${customTagData?.["v1"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v1"] ? (
                      <CardThree
                        title="Voltage 1"
                        amount={`${dashboardSts.voltage1
                          ? dashboardSts.voltage1
                          : "0"
                          } V`}
                      />
                    ) : null}

                    {/* R Phase Voltage Card */}
                    {customTagData?.["v2"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["v2"]?.sensorAlias || "Voltage 2"}
                        amount={`${dashboardSts.voltage2 ? dashboardSts.voltage2 : "0"
                          } ${customTagData?.["v2"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v2"] ? (
                      <CardThree
                        title="Voltage 2"
                        amount={`${dashboardSts.voltage2 ? dashboardSts.voltage2 : "0"
                          } V`}
                      />
                    ) : null}

                    {/* Y Phase Voltage Card */}
                    {customTagData?.["v3"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["v3"]?.sensorAlias || "Voltage 3"}
                        amount={`${dashboardSts.voltage3 ? dashboardSts.voltage3 : "0"
                          } ${customTagData?.["v3"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v3"] ? (
                      <CardThree
                        title="Voltage 3"
                        amount={`${dashboardSts.voltage3 ? dashboardSts.voltage3 : "0"
                          } V`}
                      />
                    ) : null}
                  </div>

                <div className="pb-[10px] border border-gray-400 rounded-md p-2">
                  <div className="relative  mt-2 mb-5">
                    <p>
                      Voltage data variation with time(between{" "}
                      {apiData.length > 0
                        ? apiData[0].timestamp +
                        " to " +
                        apiData[apiData.length - 1].timestamp
                        : " "}
                      )
                    </p>

                    <div className="absolute top-0 right-0">
                      <LongMenu
                        options={["Download as CSV", "Download as PDF"]}
                        onOptionClick={(exportOption) =>
                          handleExportBarChart(
                            exportOption,
                            intLNVoltPdfData,
                            chartRef1
                          )
                        }
                        id={1}
                      />
                    </div>
                    <div ref={chartRef1}>
                      <LineChart
                        data={lineChartOfVoltageVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    </div>

                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 1 && (
              <div>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Average Current Card */}
                  {customTagData?.["a_cl"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["a_cl"]?.sensorAlias ||
                        "Average Current"
                      }
                      amount={`${dashboardSts.averagecurrent ? dashboardSts.averagecurrent : "0"
                        } ${customTagData?.["a_cl"]?.sensorUnit || "A"}`}
                    />
                  ) : !customTagData?.["a_cl"] ? (
                    <CardThree
                      title="Average Current"
                      amount={`${dashboardSts.averagecurrent ? dashboardSts.averagecurrent : "0"
                        } A`}
                    />
                  ) : null}

                  {/* R Phase Current Card */}
                  {customTagData?.["cl1"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["cl1"]?.sensorAlias || "Current 1"}
                      amount={`${dashboardSts.current1 ? dashboardSts.current1 : "0"
                        } ${customTagData?.["cl1"]?.sensorUnit || "A"}`}
                    />
                  ) : !customTagData?.["cl1"] ? (
                    <CardThree
                      title="Current 1"
                      amount={`${dashboardSts.current1 ? dashboardSts.current1 : "0"
                        } A`}
                    />
                  ) : null}

                  {/* Y Phase Current Card */}
                  {customTagData?.["cl2"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["cl2"]?.sensorAlias || "Current 2"}
                      amount={`${dashboardSts.current2 ? dashboardSts.current2 : "0"
                        } ${customTagData?.["cl2"]?.sensorUnit || "A"}`}
                    />
                  ) : !customTagData?.["cl2"] ? (
                    <CardThree
                      title="Current 2"
                      amount={`${dashboardSts.current2 ? dashboardSts.current2 : "0"
                        } A`}
                    />
                  ) : null}

                  {/* B Phase Current Card */}
                  {customTagData?.["cl3"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["cl3"]?.sensorAlias || "Current 3"}
                      amount={`${dashboardSts.current3 ? dashboardSts.current3 : "0"
                        } ${customTagData?.["cl3"]?.sensorUnit || "A"}`}
                    />
                  ) : !customTagData?.["cl3"] ? (
                    <CardThree
                      title="Current 3"
                      amount={`${dashboardSts.current3 ? dashboardSts.current3 : "0"
                        } A`}
                    />
                  ) : null}
                </div>

                <div className="pb-[20px]">
                  <div className="relative mt-2 mb-5">
                    <p>
                      Current data variation with time(between{" "}
                      {apiData.length > 0
                        ? apiData[0].timestamp +
                        " to " +
                        apiData[apiData.length - 1].timestamp
                        : " "}
                      )
                    </p>
                    <div className="absolute top-0 right-0">
                      <LongMenu
                        options={["Download as CSV", "Download as PDF"]}
                        onOptionClick={(exportOption) =>
                          handleExportBarChart(
                            exportOption,
                            intCurrPdfData,
                            chartRef4
                          )
                        }
                        id={1}
                      />
                    </div>
                    <div ref={chartRef4}>
                      <LineChart
                        data={lineChartOfCurrentVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {value === 1 && valueInst === 2 && (
              <>
                <div className="grid grid-rows-2 border rounded-md border-gray-400 ">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Total Active Card */}
                    {customTagData?.["t_kw"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_kw"]?.sensorAlias || "Total Active"
                        }
                        amount={`${dashboardSts.totalactivepower ? dashboardSts.totalactivepower : "0"
                          } ${customTagData?.["t_kw"]?.sensorUnit || "kW"}`}
                      />
                    ) : !customTagData?.["t_kw"] ? (
                      <CardThree
                        title="Total Active"
                        amount={`${dashboardSts.totalactivepower ? dashboardSts.totalactivepower : "0"
                          } kW`}
                      />
                    ) : null}

                    {/* R Phase Active Power Card */}
                    {customTagData?.["kw1"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kw1"]?.sensorAlias || "Active Power 1"}
                        amount={`${dashboardSts.activepower1 ? dashboardSts.activepower1 : "0"
                          } ${customTagData?.["kw1"]?.sensorUnit || "kW"}`}
                      />
                    ) : !customTagData?.["kw1"] ? (
                      <CardThree
                        title="Active Power 1"
                        amount={`${dashboardSts.activepower1 ? dashboardSts.activepower1 : "0"
                          } kW`}
                      />
                    ) : null}

                    {/* Y Phase Active Power Card */}
                    {customTagData?.["kw2"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kw2"]?.sensorAlias || "Active Power 2"}
                        amount={`${dashboardSts.activepower2 ? dashboardSts.activepower2 : "0"
                          } ${customTagData?.["kw2"]?.sensorUnit || "kW"}`}
                      />
                    ) : !customTagData?.["kw2"] ? (
                      <CardThree
                        title="Active Power 2"
                        amount={`${dashboardSts.activepower2 ? dashboardSts.activepower2 : "0"
                          } kW`}
                      />
                    ) : null}

                    {/* B Phase Active Power Card */}
                    {customTagData?.["kw3"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kw3"]?.sensorAlias || "Active Power 3"}
                        amount={`${dashboardSts.activepower3 ? dashboardSts.activepower3 : "0"
                          } ${customTagData?.["kw3"]?.sensorUnit || "kW"}`}
                      />
                    ) : !customTagData?.["kw3"] ? (
                      <CardThree
                        title="Active Power 3"
                        amount={`${dashboardSts.activepower3 ? dashboardSts.activepower3 : "0"
                          } kW`}
                      />
                    ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Total Reactive Power Card */}
                    {customTagData?.["t_kv"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_kv"]?.sensorAlias ||
                          "Total Reactive"
                        }
                        amount={`${dashboardSts.totalreactivepower
                          ? dashboardSts.totalreactivepower
                          : "0"
                          } ${customTagData?.["t_kv"]?.sensorUnit || "kVAr"}`}
                      />
                    ) : !customTagData?.["t_kv"] ? (
                      <CardThree
                        title="Total Reactive"
                        amount={`${dashboardSts.totalreactivepower
                          ? dashboardSts.totalreactivepower
                          : "0"
                          } kVAr`}
                      />
                    ) : null}

                    {/* R Phase Reactive Power Card */}
                    {customTagData?.["kv1"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kv1"]?.sensorAlias || "Reactive Power 1"}
                        amount={`${dashboardSts.reactivepower1 ? dashboardSts.reactivepower1 : "0"
                          } ${customTagData?.["kv1"]?.sensorUnit || "kVAr"}`}
                      />
                    ) : !customTagData?.["kv1"] ? (
                      <CardThree
                        title="Reactive Power 1"
                        amount={`${dashboardSts.reactivepower1 ? dashboardSts.reactivepower1 : "0"
                          } kVAr`}
                      />
                    ) : null}

                    {/* Y Phase Reactive Power Card */}
                    {customTagData?.["kv2"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kv2"]?.sensorAlias || "Reactive Power 2"}
                        amount={`${dashboardSts.reactivepower2 ? dashboardSts.reactivepower2 : "0"
                          } ${customTagData?.["kv2"]?.sensorUnit || "kVAr"}`}
                      />
                    ) : !customTagData?.["kv2"] ? (
                      <CardThree
                        title="Reactive Power 2"
                        amount={`${dashboardSts.reactivepower2 ? dashboardSts.reactivepower2 : "0"
                          } kVAr`}
                      />
                    ) : null}

                    {/* B Phase Reactive Power Card */}
                    {customTagData?.["kv3"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kv3"]?.sensorAlias || "Reactive Power 3"}
                        amount={`${dashboardSts.reactivepower3 ? dashboardSts.reactivepower3 : "0"
                          } ${customTagData?.["kv3"]?.sensorUnit || "kVAr"}`}
                      />
                    ) : !customTagData?.["kv3"] ? (
                      <CardThree
                        title="Reactive Power 3"
                        amount={`${dashboardSts.reactivepower3 ? dashboardSts.reactivepower3 : "0"
                          } kVAr`}
                      />
                    ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Total Apparent Power Card */}
                    {customTagData?.["t_kva"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["t_kva"]?.sensorAlias ||
                          "Total Apparent"
                        }
                        amount={`${dashboardSts.totalapparentpower ? dashboardSts.totalapparentpower : "0"
                          } ${customTagData?.["t_kva"]?.sensorUnit || "kVa"}`}
                      />
                    ) : !customTagData?.["t_kva"] ? (
                      <CardThree
                        title="Total Apparent"
                        amount={`${dashboardSts.totalapparentpower ? dashboardSts.totalapparentpower : "0"
                          } kVa`}
                      />
                    ) : null}

                    {/* R Phase Apparent Power Card */}
                    {customTagData?.["kva1"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kva1"]?.sensorAlias || "Apparent Power 1"}
                        amount={`${dashboardSts.apparentpower1 ? dashboardSts.apparentpower1 : "0"
                          } ${customTagData?.["kva1"]?.sensorUnit || "kVa"}`}
                      />
                    ) : !customTagData?.["kva1"] ? (
                      <CardThree
                        title="Apparent Power 1"
                        amount={`${dashboardSts.apparentpower1 ? dashboardSts.apparentpower1 : "0"
                          } kVa`}
                      />
                    ) : null}

                    {/* Y Phase Apparent Power Card */}
                    {customTagData?.["kva2"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kva2"]?.sensorAlias || "Apparent Power 2"}
                        amount={`${dashboardSts.apparentpower2 ? dashboardSts.apparentpower2 : "0"
                          } ${customTagData?.["kva2"]?.sensorUnit || "kVa"}`}
                      />
                    ) : !customTagData?.["kva2"] ? (
                      <CardThree
                        title="Apparent Power 2"
                        amount={`${dashboardSts.apparentpower2 ? dashboardSts.apparentpower2 : "0"
                          } kVa`}
                      />
                    ) : null}

                    {/* B Phase Apparent Power Card */}
                    {customTagData?.["kva3"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["kva3"]?.sensorAlias || "Apparent Power 3"}
                        amount={`${dashboardSts.apparentpower3 ? dashboardSts.apparentpower3 : "0"
                          } ${customTagData?.["kva3"]?.sensorUnit || "kVa"}`}
                      />
                    ) : !customTagData?.["kva3"] ? (
                      <CardThree
                        title="Apparent Power 3"
                        amount={`${dashboardSts.apparentpower3 ? dashboardSts.apparentpower3 : "0"
                          } kVa`}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Power data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              getPowerDataToExport(valueInstPower),
                              chartRef5
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                    <div className="w-[270px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstPower === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(0)}
                          >
                            Active
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                            style={
                              valueInstPower === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(1)}
                          >
                            Reactive
                          </button>
                        </li>
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-e-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstPower === 2
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(2)}
                          >
                            Apparent
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ref={chartRef5}>
                    {valueInst === 2 && valueInstPower === 0 && (
                      <LineChart
                        data={lineChartOfActivePowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 2 && valueInstPower === 1 && (
                      <LineChart
                        data={lineChartOfReactivePowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 2 && valueInstPower === 2 && (
                      <LineChart
                        data={lineChartOfApparentPowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 3 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Average PF Card */}
                  {customTagData?.["a_pf"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["a_pf"]?.sensorAlias || "Average PF"
                      }
                      amount={`${dashboardSts.averagepf
                        ? dashboardSts.averagepf
                        : "0"
                        } ${customTagData?.["a_pf"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["a_pf"] ? (
                    <CardThree
                      title="Average PF"
                      amount={`${dashboardSts.averagepf
                        ? dashboardSts.averagepf
                        : "0"
                        } `}
                    />
                  ) : null}

                  {/* R Phase PF Card */}
                  {customTagData?.["pf1"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["pf1"]?.sensorAlias || "Power Factor 1"}
                      amount={`${dashboardSts.powerfactor1 ? dashboardSts.powerfactor1 : "0"
                        } ${customTagData?.["pf1"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["pf1"] ? (
                    <CardThree
                      title="Power Factor 1"
                      amount={`${dashboardSts.powerfactor1 ? dashboardSts.powerfactor1 : "0"
                        } `}
                    />
                  ) : null}

                  {/* Y Phase PF Card */}
                  {customTagData?.["pf2"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["pf2"]?.sensorAlias || "Power Factor 2"}
                      amount={`${dashboardSts.powerfactor2 ? dashboardSts.powerfactor2 : "0"
                        } ${customTagData?.["pf2"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["pf2"] ? (
                    <CardThree
                      title="Power Factor 2"
                      amount={`${dashboardSts.powerfactor2 ? dashboardSts.powerfactor2 : "0"
                        } `}
                    />
                  ) : null}

                  {/* B Phase PF Card */}
                  {customTagData?.["pf3"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["pf3"]?.sensorAlias || "Power Factor 3"}
                      amount={`${dashboardSts.powerfactor3 ? dashboardSts.powerfactor3 : "0"
                        } ${customTagData?.["pf3"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["pf3"] ? (
                    <CardThree
                      title="Power Factor 3"
                      amount={`${dashboardSts.powerfactor3 ? dashboardSts.powerfactor3 : "0"
                        } `}
                    />
                  ) : null}
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Power Factor data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              getaveragepfData,
                              chartRef6
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div ref={chartRef6}>
                    <LineChart
                      data={lineChartOfaveragepfVariations}
                      stroke={true}
                      xAxisName="Time"
                      yAxisName="Data"
                    />
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 4 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Frequency Card */}
                  {customTagData?.["freq"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["freq"]?.sensorAlias || "Frequency"
                      }
                      amount={`${dashboardSts.frequency ? dashboardSts.frequency : "0"
                        } ${customTagData?.["freq"]?.sensorUnit || "Hz"}`}
                    />
                  ) : !customTagData?.["freq"] ? (
                    <CardThree
                      title="Frequency"
                      amount={`${dashboardSts.frequency ? dashboardSts.frequency : "0"
                        } Hz`}
                    />
                  ) : null}
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Frequency data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              frequency_for_pdf,
                              chartRef7
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div ref={chartRef7}>
                    <LineChart
                      data={lineChartOfFrequencyVariations}
                      stroke={true}
                      xAxisName="Time"
                      yAxisName="Data"
                    />
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 5 && (
              <>
                <div className="grid grid-rows-2 border rounded-md border-gray-400 ">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Max Active Power Card */}
                    {customTagData?.["ap_maxd"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["ap_maxd"]?.sensorAlias ||
                          "Max Active Power"
                        }
                        amount={`${dashboardSts.mxactpower ? dashboardSts.mxactpower : "0"
                          } ${customTagData?.["ap_maxd"]?.sensorUnit || "kW"}`}
                      />
                    ) : !customTagData?.["ap_maxd"] ? (
                      <CardThree
                        title="Max Active Power"
                        amount={`${dashboardSts.mxactpower ? dashboardSts.mxactpower : "0"
                          } kW`}
                      />
                    ) : null}

                    {/* Max Reactive Power Card */}
                    {customTagData?.["rp_maxd"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["rp_maxd"]?.sensorAlias ||
                          "Max Reactive Power"
                        }
                        amount={`${dashboardSts.mxreactpower
                          ? dashboardSts.mxreactpower
                          : "0"
                          } ${customTagData?.["rp_maxd"]?.sensorUnit || "kVar"}`}
                      />
                    ) : !customTagData?.["rp_maxd"] ? (
                      <CardThree
                        title="Max Reactive Power"
                        amount={`${dashboardSts.mxreactpower
                          ? dashboardSts.mxreactpower
                          : "0"
                          } kVar`}
                      />
                    ) : null}

                    {/* Max Apparent Power Card */}
                    {customTagData?.["apr_maxd"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["apr_maxd"]?.sensorAlias ||
                          "Max Apparent Power"
                        }
                        amount={`${dashboardSts.mxapppopower
                          ? dashboardSts.mxapppopower
                          : "0"
                          } ${customTagData?.["apr_maxd"]?.sensorUnit || "kVa"}`}
                      />
                    ) : !customTagData?.["apr_maxd"] ? (
                      <CardThree
                        title="Max Apparent Power"
                        amount={`${dashboardSts.mxapppopower
                          ? dashboardSts.mxapppopower
                          : "0"
                          } kVa`}
                      />
                    ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Min Active Power Card */}
                    {customTagData?.["ap_mind"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["ap_mind"]?.sensorAlias ||
                          "Min Active Power"
                        }
                        amount={`${dashboardSts.minactpower
                          ? dashboardSts.minactpower
                          : "0"
                          } ${customTagData?.["ap_mind"]?.sensorUnit || "kW"}`}
                      />
                    ) : !customTagData?.["ap_mind"] ? (
                      <CardThree
                        title="Min Active Power"
                        amount={`${dashboardSts.minactpower
                          ? dashboardSts.minactpower
                          : "0"
                          } kW`}
                      />
                    ) : null}

                    {/* Min Reactive Power Card */}
                    {customTagData?.["rp_mind"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["rp_mind"]?.sensorAlias ||
                          "Min Reactive Power"
                        }
                        amount={`${dashboardSts.minreactpower
                          ? dashboardSts.minreactpower
                          : "0"
                          } ${customTagData?.["rp_mind"]?.sensorUnit || "kVar"}`}
                      />
                    ) : !customTagData?.["rp_mind"] ? (
                      <CardThree
                        title="Min Reactive Power"
                        amount={`${dashboardSts.minreactpower
                          ? dashboardSts.minreactpower
                          : "0"
                          } kVar`}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Power data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              getDemandDataToExport(valueInstDemand),
                              chartRef5
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                    <div className="w-[270px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstDemand === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstDemand(0)}
                          >
                            Max Demand
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                            style={
                              valueInstDemand === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstDemand(1)}
                          >
                            Min Demand
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ref={chartRef5}>
                    {valueInst === 5 && valueInstDemand === 0 && (
                      <LineChart
                        data={lineChartOfMaxDemandDataVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 5 && valueInstDemand === 1 && (
                      <LineChart
                        data={lineChartOfMinDemandDataVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 6 && (
              <>
                <div className="grid grid-rows-2border rounded-md border-gray-400 ">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* R Phase Voltage THD */}
                    {customTagData?.["thd_v1n"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_v1n"]?.sensorAlias ||
                          "R Phase Voltage"
                        }
                        amount={`${dashboardSts.rvtgphthd ? dashboardSts.rvtgphthd : "0"
                          } ${customTagData?.["thd_v1n"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_v1n"] ? (
                      <CardThree
                        title="R Phase Voltage"
                        amount={`${dashboardSts.rvtgphthd ? dashboardSts.rvtgphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* Y Phase Voltage THD */}
                    {customTagData?.["thd_v2n"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_v2n"]?.sensorAlias ||
                          "Y Phase Voltage"
                        }
                        amount={`${dashboardSts.yvtgphthd ? dashboardSts.yvtgphthd : "0"
                          } ${customTagData?.["thd_v2n"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_v2n"] ? (
                      <CardThree
                        title="Y Phase Voltage"
                        amount={`${dashboardSts.yvtgphthd ? dashboardSts.yvtgphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* B Phase Voltage THD */}
                    {customTagData?.["thd_v3n"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_v3n"]?.sensorAlias ||
                          "B Phase Voltage"
                        }
                        amount={`${dashboardSts.bvtgphthd ? dashboardSts.bvtgphthd : "0"
                          } ${customTagData?.["thd_v3n"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_v3n"] ? (
                      <CardThree
                        title="B Phase Voltage"
                        amount={`${dashboardSts.bvtgphthd ? dashboardSts.bvtgphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* Empty Div */}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* RY Phase Voltage THD */}
                    {customTagData?.["thd_v12"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_v12"]?.sensorAlias ||
                          "RY Phase Voltage"
                        }
                        amount={`${dashboardSts.ryvtgphthd ? dashboardSts.ryvtgphthd : "0"
                          } ${customTagData?.["thd_v12"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_v12"] ? (
                      <CardThree
                        title="RY Phase Voltage"
                        amount={`${dashboardSts.ryvtgphthd ? dashboardSts.ryvtgphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* YB Phase Voltage THD */}
                    {customTagData?.["thd_v23"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_v23"]?.sensorAlias ||
                          "YB Phase Voltage"
                        }
                        amount={`${dashboardSts.ybvtgphthd ? dashboardSts.ybvtgphthd : "0"
                          } ${customTagData?.["thd_v23"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_v23"] ? (
                      <CardThree
                        title="YB Phase Voltage"
                        amount={`${dashboardSts.ybvtgphthd ? dashboardSts.ybvtgphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* RB Phase Voltage THD */}
                    {customTagData?.["thd_v31"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_v31"]?.sensorAlias ||
                          "RB Phase Voltage"
                        }
                        amount={`${dashboardSts.rbvtgphthd ? dashboardSts.rbvtgphthd : "0"
                          } ${customTagData?.["thd_v31"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_v31"] ? (
                      <CardThree
                        title="RB Phase Voltage"
                        amount={`${dashboardSts.rbvtgphthd ? dashboardSts.rbvtgphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* Empty Div */}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* R Phase Current THD */}
                    {customTagData?.["thd_ci1"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_ci1"]?.sensorAlias ||
                          "R Phase Current"
                        }
                        amount={`${dashboardSts.rcrtphthd ? dashboardSts.rcrtphthd : "0"
                          } ${customTagData?.["thd_ci1"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_ci1"] ? (
                      <CardThree
                        title="R Phase Current"
                        amount={`${dashboardSts.rcrtphthd ? dashboardSts.rcrtphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* Y Phase Current THD */}
                    {customTagData?.["thd_ci2"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_ci2"]?.sensorAlias ||
                          "Y Phase Current"
                        }
                        amount={`${dashboardSts.ycrtphthd ? dashboardSts.ycrtphthd : "0"
                          } ${customTagData?.["thd_ci2"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_ci2"] ? (
                      <CardThree
                        title="Y Phase Current"
                        amount={`${dashboardSts.ycrtphthd ? dashboardSts.ycrtphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* B Phase Current THD */}
                    {customTagData?.["thd_ci3"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["thd_ci3"]?.sensorAlias ||
                          "B Phase Current"
                        }
                        amount={`${dashboardSts.bcrtphthd ? dashboardSts.bcrtphthd : "0"
                          } ${customTagData?.["thd_ci3"]?.sensorUnit || "%"}`}
                      />
                    ) : !customTagData?.["thd_ci3"] ? (
                      <CardThree
                        title="B Phase Current"
                        amount={`${dashboardSts.bcrtphthd ? dashboardSts.bcrtphthd : "0"
                          } %`}
                      />
                    ) : null}

                    {/* Empty Div */}
                  </div>
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Threshold data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              getTDHDataToExport,
                              chartRef8
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                    <div className="w-[500px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex">
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstTDH === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstTDH(0)}
                          >
                            Neutral Voltage
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
                            style={
                              valueInstTDH === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstTDH(1)}
                          >
                            Voltage Line-Line
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                            style={
                              valueInstTDH === 2
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstTDH(2)}
                          >
                            Current
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ref={chartRef8}>
                    {valueInst === 6 && valueInstTDH === 0 && (
                      <LineChart
                        data={lineChartOfTHDVoltageVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 6 && valueInstTDH === 1 && (
                      <LineChart
                        data={lineChartOfTHDLineToLineVoltVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 6 && valueInstTDH === 2 && (
                      <LineChart
                        data={lineChartOfTHDCurrentVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 7 && (
              <>
                <div className="grid grid-rows-2 border rounded-md border-gray-400 ">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Run Hours Card */}
                    {customTagData?.["r_hour"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["r_hour"]?.sensorAlias || "Run Hours"
                        }
                        amount={`${dashboardSts.runhours ? dashboardSts.runhours : "0"
                          } ${customTagData?.["r_hour"]?.sensorUnit || "Hr."}`}
                      />
                    ) : !customTagData?.["r_hour"] ? (
                      <CardThree
                        title="Run Hours"
                        amount={`${dashboardSts.runhours ? dashboardSts.runhours : "0"
                          } Hr.`}
                      />
                    ) : null}

                    {/* Current Interrupt Card */}
                    {customTagData?.["a_intrpt"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["a_intrpt"]?.sensorAlias ||
                          "Current Interrupt"
                        }
                        amount={`${dashboardSts.currintrrpt
                          ? dashboardSts.currintrrpt
                          : "0"
                          } ${customTagData?.["a_intrpt"]?.sensorUnit || ""}`}
                      />
                    ) : !customTagData?.["a_intrpt"] ? (
                      <CardThree
                        title="Current Interrupt"
                        amount={`${dashboardSts.currintrrpt
                          ? dashboardSts.currintrrpt
                          : "0"
                          }`}
                      />
                    ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* R Phase Voltage Card */}
                    {customTagData?.["max_iv1n"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_iv1n"]?.sensorAlias ||
                          "R Phase Voltage"
                        }
                        amount={`${dashboardSts.rphvoltage ? dashboardSts.rphvoltage : "0"
                          } ${customTagData?.["max_iv1n"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_iv1n"] ? (
                      <CardThree
                        title="R Phase Voltage"
                        amount={`${dashboardSts.rphvoltage ? dashboardSts.rphvoltage : "0"
                          } (max A)`}
                      />
                    ) : null}

                    {/* Y Phase Voltage Card */}
                    {customTagData?.["max_iv2n"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_iv2n"]?.sensorAlias ||
                          "Y Phase Voltage"
                        }
                        amount={`${dashboardSts.yphvoltage ? dashboardSts.yphvoltage : "0"
                          } ${customTagData?.["max_iv2n"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_iv2n"] ? (
                      <CardThree
                        title="Y Phase Voltage"
                        amount={`${dashboardSts.yphvoltage ? dashboardSts.yphvoltage : "0"
                          } (max A)`}
                      />
                    ) : null}

                    {/* B Phase Voltage Card */}
                    {customTagData?.["max_iv3n"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_iv3n"]?.sensorAlias ||
                          "B Phase Voltage"
                        }
                        amount={`${dashboardSts.bphvoltage ? dashboardSts.bphvoltage : "0"
                          } ${customTagData?.["max_iv3n"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_iv3n"] ? (
                      <CardThree
                        title="B Phase Voltage"
                        amount={`${dashboardSts.bphvoltage ? dashboardSts.bphvoltage : "0"
                          } (max A)`}
                      />
                    ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* RY Phase Voltage Card */}
                    {customTagData?.["max_iv12"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_iv12"]?.sensorAlias ||
                          "RY Phase Voltage"
                        }
                        amount={`${dashboardSts.ryphvoltage
                          ? dashboardSts.ryphvoltage
                          : "0"
                          } ${customTagData?.["max_iv12"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_iv12"] ? (
                      <CardThree
                        title="RY Phase Voltage"
                        amount={`${dashboardSts.ryphvoltage
                          ? dashboardSts.ryphvoltage
                          : "0"
                          } (max A)`}
                      />
                    ) : null}

                    {/* YB Phase Voltage Card */}
                    {customTagData?.["max_iv23"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_iv23"]?.sensorAlias ||
                          "YB Phase Voltage"
                        }
                        amount={`${dashboardSts.ybphvoltage
                          ? dashboardSts.ybphvoltage
                          : "0"
                          } ${customTagData?.["max_iv23"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_iv23"] ? (
                      <CardThree
                        title="YB Phase Voltage"
                        amount={`${dashboardSts.ybphvoltage
                          ? dashboardSts.ybphvoltage
                          : "0"
                          } (max A)`}
                      />
                    ) : null}

                    {/* RB Phase Voltage Card */}
                    {customTagData?.["max_iv31"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_iv31"]?.sensorAlias ||
                          "RB Phase Voltage"
                        }
                        amount={`${dashboardSts.rbphvoltage
                          ? dashboardSts.rbphvoltage
                          : "0"
                          } ${customTagData?.["max_iv31"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_iv31"] ? (
                      <CardThree
                        title="RB Phase Voltage"
                        amount={`${dashboardSts.rbphvoltage
                          ? dashboardSts.rbphvoltage
                          : "0"
                          } (max A)`}
                      />
                    ) : null}

                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* R Phase Current Card */}
                    {customTagData?.["max_ici1"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_ici1"]?.sensorAlias ||
                          "R Phase Current"
                        }
                        amount={`${dashboardSts.rphcuurent ? dashboardSts.rphcuurent : "0"
                          } ${customTagData?.["max_ici1"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_ici1"] ? (
                      <CardThree
                        title="R Phase Current"
                        amount={`${dashboardSts.rphcuurent ? dashboardSts.rphcuurent : "0"
                          } (max A)`}
                      />
                    ) : null}

                    {/* Y Phase Current Card */}
                    {customTagData?.["max_ici2"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_ici2"]?.sensorAlias ||
                          "Y Phase Current"
                        }
                        amount={`${dashboardSts.yphcurrent ? dashboardSts.yphcurrent : "0"
                          } ${customTagData?.["max_ici2"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_ici2"] ? (
                      <CardThree
                        title="Y Phase Current"
                        amount={`${dashboardSts.yphcurrent ? dashboardSts.yphcurrent : "0"
                          } (max A)`}
                      />
                    ) : null}

                    {/* B Phase Current Card */}
                    {customTagData?.["max_ici3"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["max_ici3"]?.sensorAlias ||
                          "B Phase Current"
                        }
                        amount={`${dashboardSts.bphcurrent ? dashboardSts.bphcurrent : "0"
                          } ${customTagData?.["max_ici3"]?.sensorUnit || "(max A)"
                          }`}
                      />
                    ) : !customTagData?.["max_ici3"] ? (
                      <CardThree
                        title="B Phase Current"
                        amount={`${dashboardSts.bphcurrent ? dashboardSts.bphcurrent : "0"
                          } (max A)`}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardAwfisEnergy;
