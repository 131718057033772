import {
  Box,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { useScheduleState } from "../../app-context/scheduling-context/SchedulingState";
import { useEffect, useMemo, useState } from "react";
import LongMenu from "../../utils/longMenu/LongMenu";
import { ConfirmModal } from "../../utils/alertui/ConfirmModal";
import { http } from "../../helper/http";
import { setSchedule } from "../../app-context/scheduling-context/SchedulingAction";
import AddSchedulingForm from "./AddSchedulingForm";
import { ScheduleApiResponseData } from "../../types/Schedule";
import ProtectedComponent from "../../utils/protected-route/ProtectedComponent";

const baseUrl = process.env.REACT_APP_BASE_URL;
let __editScheduleData: ScheduleApiResponseData;
let deleteId: number;
const Scheduling = () => {
  const { schedulings, dispatch } = useScheduleState();
  const [updatedRows, setUpdatedRows] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editScheduleModal, setEditScheduleModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [search1, setSearch1] = useState("");
  const weekdayMapping: any = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun",
  };



useEffect(()=>{
  setSchedule(dispatch, 1)
},[])  

  // useEffect(() => {
  //   setUpdatedRows(schedulings.schedulings);
  // },[schedulings.schedulings]);
  // const emptyRows = 10 - updatedRows.length;
  // const visibleRows = useMemo(
  //   () => updatedRows.slice(0 * 10, 0 * 10 + 10),
  //   [updatedRows]
  // );

  const visibleRows = useMemo(() => {
    const startIndex = currentPage * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return schedulings.schedulings.slice(startIndex, endIndex);
  }, [schedulings.schedulings, currentPage, rowsPerPage]);
  

  const handleSwitchToggle = async (id: number, status: any, key: string) => {
    try {
      await http(
        `${baseUrl}/scheduling?id=${id}&${key === 'SMS' ? `smsStatus=${!status}&key=SMS` : key === "EMAIL" ? `emailStatus=${!status}&key=EMAIL` : `schedulerStatus=${!status}&key=SCHEDULE`}`,
        "PATCH"
      ) as any;
      setSchedule(dispatch, 1)

    } catch (error) {
      console.error(error);
      
    }
    
  };

  const handleCommandStatusSwitchToggle = async (id: number, status: any, key: string) => {
    try {
      const updatedStatus = !status;
      await http(`${baseUrl}/scheduling?id=${id}&onCmdStatus=${updatedStatus}&key=${key}`,
        "PATCH"
      ) as any;
      setSchedule(dispatch, 1);
    } catch (err){
      console.error(err);
    }
  }

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
        try {
             await http(
                baseUrl + `/scheduling?id=${deleteId}`,
                "DELETE"
              ) as any;
              setSchedule(dispatch, schedulings.currentPage) 
              
        } catch (error) {
            console.error(error);
            
        }
        setShowConfirmModal(false);
        
    }
  }

  const handleMenuOptionClick = async (option: string, id: number) => {
    try {
        switch (option) {
          case "Delete":
            deleteId = id;
            setShowConfirmModal(true);
            break;
          case "Edit":
            const editSchedulings = schedulings.schedulings.find((schedulings) => schedulings.id === id);
            __editScheduleData = editSchedulings;
            setEditScheduleModal(true)
            break;
        }
      } catch (err) {
        console.error(err);
      }
  };

  useEffect(() => {
    setSchedule(dispatch, currentPage); // Fetch data for the current page
  }, [currentPage, dispatch]);

  // const handlePageChange = (event: unknown, newPage: number) => {
  //   setCurrentPage(newPage);
    
  // }

  const handlePageChange = async (newPage: number) => {
    const direction = newPage > schedulings.currentPage - 1 ? "next" : "previous";
    const page = +schedulings.currentPage;
    try {
      const d = direction === "next" ? page + 1 : page - 1;
      // const resp = (await http(
      //   baseUrl + `/device?page=${direction === "next" ? page + 1 : page - 1}`
      // )) as DeviceApiResponse;
      // getDevicePageClick(dispatch, resp.devices);
      setSchedule(dispatch, d, search1);
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    setSchedule(dispatch, 1, search1);
  }, [search1])


  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <EnhancedTableToolbar search={search1} setSearch={setSearch1} />
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
              {showConfirmModal && (
                <ConfirmModal
                  title="Confirmation"
                  message="Are you sure you want to delete?"
                  onConfirm={handleConfirmDelete}
                  onCancel={() => {
                    setShowConfirmModal(false);
                  }}
                  isVisible={showConfirmModal}
                />
              )}
              <Table
                className="min-w-750 bg-white"
                aria-labelledby="tableTitle"
                size="medium"
              >
                {editScheduleModal && (
                  <AddSchedulingForm
                    uniqueId="EDIT"
                    data={__editScheduleData as any}
                    toggleForm={editScheduleModal}
                    toggleFormFun={() => {
                        setEditScheduleModal(false);
                    }}
                  />
                )}
                <EnhancedTableHead />
                <TableBody>
                  {visibleRows.map((row: any) => (
                    <TableRow
                      key={row?.id || null}
                      hover
                      className="cursor-pointer"
                    >
                      {row && (
                        <>
                          <TableCell align="center" className="py-3">
                            {row?.schedulingName || "N/A"}
                          </TableCell>
                          <TableCell align="center" className="py-3">
                            {row?.device?.deviceName || "N/A"}
                          </TableCell>
                          <TableCell align="center" className="py-3">
                            {row?.email && row.email.length > 0
                              ? row.email.map((email: any, index: any) => (
                                  <div key={index}>
                                    {email}
                                    {index < row.email.length - 1 && (
                                      <br />
                                    )}{" "}
                                  </div>
                                ))
                              : "N/A"}
                          </TableCell>

                          {/* commented as per the requirement */}
                          {/* <TableCell align="center" className="py-3">
                            {row?.phone && row.phone.length > 0
                              ? row.phone.map((phone: any, index: any) => (
                                  <div key={index}>
                                    {phone}
                                    {index < row.phone.length - 1 && (
                                      <br />
                                    )}{" "}
                                  </div>
                                ))
                              : "N/A"}
                          </TableCell> */}

                          {/* <TableCell align="center" className="py-3">
                            <Switch
                              checked={
                                row.smsStatus !== undefined
                                  ? row.smsStatus
                                  : false
                              }
                              color="primary"
                              onChange={() =>
                                handleSwitchToggle(row.id, row.smsStatus, "SMS")
                              }
                            />
                          </TableCell> */}

                          <TableCell align="center" className="py-3">
                            <Switch
                              checked={
                                row.emailStatus !== undefined
                                  ? row.emailStatus
                                  : false
                              }
                              color="primary"
                              onChange={() =>
                                handleSwitchToggle(
                                  row.id,
                                  row.emailStatus,
                                  "EMAIL"
                                )
                              }
                            />
                          </TableCell>

                          <TableCell align="center" className="py-3">
                            {row?.workingDay && row.workingDay.length > 0
                              ? row.workingDay
                                  .map((day: any) => weekdayMapping[day])
                                  .join(", ")
                              : "N/A"}
                          </TableCell>

                          <TableCell align="center" className="py-3">
                            {row?.cooldownInterval !== undefined
                              ? `${row.cooldownInterval} Min`
                              : "N/A"}
                          </TableCell>
                          <ProtectedComponent roleids={[1]}>
                            <TableCell align="center" className="py-3">
                              <Switch
                                checked={!!row.onCmdStatus}
                                color="primary"
                                onChange={() =>
                                  handleCommandStatusSwitchToggle(
                                    row.id,
                                    row.onCmdStatus,
                                    "ONCMD"
                                  )
                                }
                              />
                            </TableCell>
                          </ProtectedComponent>

                          <TableCell align="center" className="py-3">
                            <Switch
                              checked={
                                row.schedulerStatus!== undefined
                                  ? row.schedulerStatus
                                  : false
                              }
                              color="primary"
                              onChange={() =>
                                handleSwitchToggle(
                                  row.id,
                                  row.schedulerStatus,
                                  "SCHEDULE"
                                )
                              }
                            />
                          </TableCell>

                          <TableCell
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            <LongMenu
                              options={["Edit", "Delete"]}
                              onOptionClick={handleMenuOptionClick}
                              id={row.id}
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                  {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 45 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={4} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]} // Dropdown to change rows per page
            component="div"
            count={schedulings.schedulings.length} // Total number of rows
            rowsPerPage={rowsPerPage} // Rows per page
            page={currentPage} // Current page (directly use 0-based index)
            onPageChange={(event, newPage) => handlePageChange(newPage)} // Handle page change
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10)); // Update rows per page
              setCurrentPage(0); // Reset to the first page when rows per page change
            }}
            className="bg-gray-100"
          />
        </Paper>
      </Box>
    </>
  );
};

export default Scheduling;
