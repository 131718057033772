import React, { useEffect, useState } from 'react';
import ContactusImg from '../../images/contactUs.png'
import { useUserState } from '../../app-context/auth-context/AuthState';
import {http} from '../../helper/http';
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

interface ContactInfo {
    name: string;
    phone: string;
    address: string;
  }
  
interface CustomerDetails {
    id: number;
    customerId: number;
    userId: number;
    contactInfo: ContactInfo[];
    createdAt: string; // Use `Date` if you're parsing this string into a Date object
    updatedAt: string; // Use `Date` if you're parsing this string into a Date object
  }

const ContactUs = () => {
    const [contactDetails, setContactDetails] = useState<any>(null);
    const {users} = useUserState();

    const fetchContactDetails = async(id:number) => {
        try {
            const response = (await http(`${baseUrl}/contact-us?userId=${id}`)) as any;
            setContactDetails(response);
            toast.success(response.message);
        } catch(error){
            console.log('printy error: ', error);
            toast.error(error as any);
        }
    }

    useEffect(() => {
        fetchContactDetails(users.user.id);
    }, [users]);

    return (
        <>
            <div className='p-4'>
                <div className='flex w-full flex-col xl:flex-row items-center'>
                    <div className='flex-1'>
                        <h1 className='text-4xl font-bold'>Contact Us</h1>
                        <img src={ContactusImg} alt="" />
                    </div>
                    {contactDetails?.contactInfo?.length > 0 ? (
                        <div className='flex-1'>
                            <div className='border border-black rounded-xl py-4 px-10 flex flex-col gap-y-10'>
                                {contactDetails.contactInfo.map((info: ContactInfo) => (
                                    <div className='bg-gray-300 rounded-lg py-5 px-10 flex justify-between gap-x-10'>
                                        <p className='flex-2 text-2xl font-semibold'>{info.name}</p>
                                        <div className='flex-1'>
                                            <div className='flex'>
                                            <p><strong>Address:</strong>&nbsp;
                                                {info.address}</p>
                                            </div>
                                            <p className='flex'>
                                                <strong>Phone Number:</strong>
                                                <span>{info.phone}</span>
                                            </p>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default ContactUs;
