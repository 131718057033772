import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to receive passed data
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';
import { styled, tableCellClasses } from '@mui/material';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.grey[200], // Customize the hover color here
    },
}));

const SchedulerAlertData = () => {
    const location = useLocation();
    const logs = location.state?.logs || []; // Access the logs passed via state
    const deviceName = location.state?.deviceName || 'N/A';

    const enrichedLogs = logs.map((log: any) => ({
        ...log,
        deviceName,
    }))
    console.log('print logs:', enrichedLogs);

    const handleDownloadExcel = () => {
        if(enrichedLogs.length === 0){
            alert('No data available to download!');
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(enrichedLogs);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Device Logs');

        XLSX.writeFile(workbook, 'Device_Logs.xlsx');
    } 

    return (
        <div>
            <h1 className='text-2xl font-semibold'>Device Alert Logs</h1>
            <div className='text-right py-4'>
                <Button variant='contained' startIcon={<DownloadIcon/>} onClick={handleDownloadExcel}>Download Report in Excel</Button>
            </div>
            {enrichedLogs.length > 0 ? (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table aria-label="device logs table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Device Name</StyledTableCell>
                                    <StyledTableCell>Event Message</StyledTableCell>
                                    <StyledTableCell>Condition</StyledTableCell>
                                    {/* <StyledTableCell>Temperature</StyledTableCell>
                                    <StyledTableCell>Humidity</StyledTableCell> */}
                                    <StyledTableCell>Event Time</StyledTableCell>
                                    {/* <StyledTableCell>AC Status</StyledTableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {enrichedLogs.map((log: any, index: number) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{log.deviceName}</StyledTableCell>
                                        <StyledTableCell>{log.eventMessage}</StyledTableCell>
                                        <StyledTableCell>{log.condition}</StyledTableCell>
                                        {/* <StyledTableCell>{log.temperature}</StyledTableCell>
                                        <StyledTableCell>{log.humidity}</StyledTableCell> */}
                                        <StyledTableCell>{log.eventTime}</StyledTableCell>
                                        {/* <StyledTableCell>{log.acStatus}</StyledTableCell> */}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : (
                <p>No logs available for this device.</p>
            )}
        </div>
    );
};

export default SchedulerAlertData;
